import React from 'react';
import {graphql} from 'gatsby';
import {useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {getFirstNames} from '@om/redux/selectors/taxFreeSavingsAccount/personalDetails';
import {getReferenceNumber} from '@om/redux/selectors/taxFreeSavingsAccount/review';
import './ThankYouPage.scss';
import { OmApplicationThankYou } from '@om/component-library-react';
import FourColumnIllustration from '../../../components/FourColumnIllustration';

const ThankYouPage = ({
    thankYouEntry,
    fourColumnLayout,
}) => {
    const { 
        firstNames, 
        referenceNumber
    } = useSelector(
        createStructuredSelector({
            firstNames: getFirstNames,
            referenceNumber: getReferenceNumber
        })
    );

    const {
        close_button_link,
        success_image_link,
        caption,
        contact_caption,
        button
    } = thankYouEntry[0];

    return (
        <div className="tfsa-thank-you-page">
            <OmApplicationThankYou
                closeButtonLink={close_button_link && close_button_link.href ? close_button_link.href : ''}
                imageLink={success_image_link && success_image_link.href ? success_image_link.href : ''}
                personName={firstNames.value}
                caption={caption}
                referenceNumber={referenceNumber}
                contactCaption={contact_caption}
                buttonName={button ? button.title : ''}
                buttonLink={button ? button.href : ''}
                thankYouCaption={caption}
            />

            <FourColumnIllustration
                fourColumnLayout={fourColumnLayout}
            />
        </div>
    );
};

export const pageQuery = graphql`
    fragment ThankYou on Contentstack_thank_you {
        close_button_link {
            ...Link
        }
        success_image_link {
            ...Link
        }
        caption
        contact_caption
        button {
            title
            ...Link
        }
    }
`;

export default ThankYouPage;


import React, {useState} from 'react';
import {
    OmIcon,
    OmLabeledValueSummary,
    OmApplicationRadioButtonAccordionItemBold,
    OmApplicationPopupModal,
} from '@om/component-library-react';
import OmButton from '../../../components/forms/OmButton';

const FundSelectionRadioAccordionItem = ({
    tickListItems,
    radioButtonTitle,
    fundName,
    factSheetUrl,
    investmentInfo,
    buttonId,
    itemTitle,
    borderColour,
    infoPopupHeading,
    infoPopupText,
    selected
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <OmApplicationRadioButtonAccordionItemBold
            buttonGroupName="selectAFund"
            numberOfAccordions="3"
            informationDisplayMode
            radioButtonTitle={radioButtonTitle}
            radioButtonText={itemTitle}
            radioButtonId={buttonId}
            borderColour={borderColour}
            selected={selected}
            onModalIconClicked={() => setIsModalOpen(!isModalOpen)}
        >
            <div slot="button-description">
                {tickListItems && tickListItems.map((item, index) => (
                    <div className="icon-with-fund-details" key={`${buttonId}_button-description_${index}`}>
                        <OmIcon iconName="check" height="18" width="18" />
                        <h6 className="small no-top-margin no-bottom-margin">
                            {item}
                        </h6>
                    </div>
                ))}
            </div>

            <div slot="panel-content">
                <div className="panel-heading">
                    <h6 className="no-top-margin no-bottom-margin"><small>The associated fund is </small></h6>
                    <h6 className="no-top-margin no-bottom-margin"><strong>{fundName}</strong></h6>
                </div>

                <div className="investment-labels">
                    {investmentInfo && investmentInfo.map((info, index) => (
                        <div className="investment-label-row" key={`${buttonId}_investment-labels_${index}`}>
                            <OmLabeledValueSummary
                                className="description-text"
                                value={info.label}
                            />
                            {Array.isArray(info.value)
                                ? (info.value.map((summary, key) => (
                                    <OmLabeledValueSummary
                                        className="three-values"
                                        key={`${buttonId}_three-label_${key}`}
                                        label={summary.label}
                                        value={summary.value}
                                    />
                                )))
                                : <OmLabeledValueSummary
                                    className="single-value"
                                    value={info.value}
                                />
                            }
                        </div>
                    ))}
                </div>

                <div className="panel-button-wrapper">
                    <OmButton
                        text="FUND FACT SHEET"
                        type="secondary"
                        actionType="url"
                        size="small"
                        openTab
                        url={factSheetUrl}
                    />
                </div>
            </div>

            <OmApplicationPopupModal
                exitable
                dismissible
                slot="pop-up-modal"
                name="fundInformationModal"
                open={isModalOpen}
                onModalClosed={() => setIsModalOpen(false)}
            >
                <div slot="content">
                    <h5 className="no-top-margin no-bottom-margin">
                        {infoPopupHeading}
                    </h5>
                    <p>
                        {infoPopupText}
                    </p>
                </div>
            </OmApplicationPopupModal>
        </OmApplicationRadioButtonAccordionItemBold>
    );
};

export default  FundSelectionRadioAccordionItem;

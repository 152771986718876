import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './AdvancedFundPickerPage.scss';
import { 
    getIsSearchOrFiltering,
    getSelectedFunds,
    getDisplayMaxFundsModal
} from '@om/redux/selectors/taxFreeSavingsAccount/advancedFundPicker';
import { toggleMaxFundsModal } from '@om/redux/actions/taxFreeSavingsAccount/advancedFundPicker';
import FilterSideDrawer from './fundFiltering/FilterSideDrawer';
import DefaultFundsView from './fundResultsViews/DefaultResultsView';
import SearchResultsFundsView from './fundResultsViews/SearchResultsView';
import SearchBar from './searchBar/SearchBar';
import OmButton from '../../../components/forms/OmButton';
import { OmApplicationPopupModal } from '@om/component-library-react';
import { FIELD_STATUS } from '@om/redux/reducers/status';

const AdvancedFundPickerPage = () => {
    const dispatch = useDispatch();
    const { isSearchOrFiltering, selectedFunds, displayMaxFundsModal } = useSelector(
        createStructuredSelector({
            isSearchOrFiltering: getIsSearchOrFiltering,
            selectedFunds: getSelectedFunds,
            displayMaxFundsModal: getDisplayMaxFundsModal,
        })
    );

    return (
        <div className="tfsa-advanced-fund-picker-page">
            <div className="tfsa-advanced-fund-picker-wrapper">
                <h3 className="no-top-margin no-bottom-margin">
                    Choose your <strong>own fund(s)</strong>
                </h3>
                <p> Select one or more funds where your money will be invested. </p>

                <SearchBar />
                <FilterSideDrawer />
                {!isSearchOrFiltering
                    ? <DefaultFundsView />
                    : <SearchResultsFundsView />
                }

                <OmApplicationPopupModal
                    exitable
                    dismissible
                    name="advancedFundMaxFundsSelected"
                    open={displayMaxFundsModal}
                    onModalClosed={() => dispatch(toggleMaxFundsModal(false))}
                >
                    <div slot="content">
                        <h5 className="strong no-bottom-margin no-top-margin">Fund selection limit reached</h5>
                        <p className="no-bottom-margin">
                            <small>
                                You can only invest a minimum of R100 per fund. Consider topping
                                up your investment in order to add more funds.
                            </small>
                        </p>
                    </div>
                </OmApplicationPopupModal>

                <OmApplicationPopupModal
                    exitable
                    dismissible
                    name="advancedFundFormErrorModal"
                    open={selectedFunds.status === FIELD_STATUS.ERROR}
                >
                    <div slot="content">
                        <p> <strong>Please select at least one fund</strong> to continue. </p>
                    </div>
                    <div slot="button">
                        <OmButton
                            type="primary"
                            size="small"
                            text="Okay, thanks"
                        />
                    </div>
                </OmApplicationPopupModal>
            </div>
        </div>

    );
};

export default AdvancedFundPickerPage;

import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmFormDropdownFieldWrapper, OmFormInputFieldWrapper, OmRadioButtonGroup } from '@om/component-library-react';

import {
    getMaritalStatus,
    getIsMarriedInCommunityOfProperty,
    getSpouseTitle,
    getSpouseFirstNames,
    getSpouseSurname,
    getSpouseIdNumber,
    getSpouseHasIdNumber,
    getSpousePassportNumber,
} from '@om/redux/selectors/taxFreeSavingsAccount/auxiliaryDetails';
import {
    setMaritalStatusValue,
    setSpouseTitleValue,
    setSpouseFirstNamesValue,
    setSpouseSurnameValue,
    setSpouseIdNumberValue,
    setSpousePassportNumberValue,
    toggleSpouseIdTypeValue,
} from '@om/redux/actions/taxFreeSavingsAccount/auxiliaryDetails';
import {
    getReferenceTitles,
    getReferenceMaritalStatuses
} from '@om/redux/selectors/taxFreeSavingsAccount/referenceData';

const MarriageDetailsSection = () => {
    const [spouseFirstNamesInput, setSpouseFirstNamesInput] = useState();
    const [spouseSurnameInput, setSpouseSurnameInput] = useState();
    const [spouseIdNumberInput, setSpouseIdNumberInput] = useState();
    const [spousePassportNumberInput, setSpousePassportNumberInput] = useState();
    const dispatch = useDispatch();
    const {
        maritalStatus,
        isMarriedInCommunityOfProperty,
        spouseTitle,
        spouseFirstName,
        spouseSurname,
        spouseIdentityType,
        spouseIdNumber,
        spousePassportNumber,

        referenceTitles,
        referenceMaritalStatuses
    } = useSelector(
        createStructuredSelector({
            maritalStatus: getMaritalStatus,
            isMarriedInCommunityOfProperty: getIsMarriedInCommunityOfProperty,
            spouseTitle: getSpouseTitle,
            spouseFirstName: getSpouseFirstNames,
            spouseSurname: getSpouseSurname,
            spouseIdentityType: getSpouseHasIdNumber,
            spouseIdNumber: getSpouseIdNumber,
            spousePassportNumber: getSpousePassportNumber,

            referenceTitles: getReferenceTitles,
            referenceMaritalStatuses: getReferenceMaritalStatuses,
        })
    );

    return (
        <>
            <OmFormDropdownFieldWrapper
                id="maritalStatus"
                useNativeMobileDropdown
                required
                size="medium"
                formId="personalDetailsForm"
                placeholder="Marital status"
                name="title"
                align="left"
                value={maritalStatus.value}
                state={maritalStatus.status}
                errorMessage={maritalStatus.error}
                options={ referenceMaritalStatuses &&
                    JSON.stringify(referenceMaritalStatuses.map(option => ({ value: option.code, label: option.description }))) 
                }
                onOnSelect={(event) => dispatch(setMaritalStatusValue(event.detail.id)) }
            />
            {isMarriedInCommunityOfProperty &&
                <section>
                    <h6  className="medium no-bottom-margin">
                        Spouse details
                    </h6>
                    <OmFormDropdownFieldWrapper
                        id="spouseTitle"
                        useNativeMobileDropdown
                        required
                        size="medium"
                        formId="personalDetailsForm"
                        placeholder="Title"
                        name="title"
                        align="left"
                        value={spouseTitle.value}
                        state={spouseTitle.status}
                        errorMessage={spouseTitle.error}
                        options={ referenceTitles &&
                            JSON.stringify(referenceTitles.map(option => ({ value: option.code, label: option.description }))) 
                        }
                        onOnSelect={(event) => dispatch(setSpouseTitleValue(event.detail.id)) }
                    />
                    <OmFormInputFieldWrapper
                        id="spouseFirstNames"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="First Name(s)"
                        validationDelay={0}
                        value={spouseFirstName.value}
                        state={spouseFirstNamesInput?.status}
                        errorMessage={spouseFirstNamesInput?.error}
                        onPersist={event => dispatch(setSpouseFirstNamesValue(event.detail))}
                        onInputBlur={() => setSpouseFirstNamesInput(spouseFirstName)}
                        onFocus={() => setSpouseFirstNamesInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="spouseSurname"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="Surname"
                        validationDelay={0}
                        value={spouseSurname.value}
                        state={spouseSurnameInput?.status}
                        errorMessage={spouseSurnameInput?.error}
                        onPersist={event => dispatch(setSpouseSurnameValue(event.detail))}
                        onInputBlur={() => setSpouseSurnameInput(spouseSurname)}
                        onFocus={() => setSpouseSurnameInput(null)}
                    />
                    <h6
                        className="medium radio-button-heading"
                        id="spouseIdType">
                            Please select preferred identity type:
                    </h6>
                    <OmRadioButtonGroup
                        vertical
                        value={spouseIdentityType}
                        groupName="spouseIdentityType"
                        onOnSelection={(event) => dispatch(toggleSpouseIdTypeValue())}>
                        <ul slot="list-items">
                            <li value="true">South African ID</li>
                            <li value="false">Passport</li>
                        </ul>
                    </OmRadioButtonGroup>
                </section>
            }
            {isMarriedInCommunityOfProperty && spouseIdentityType &&
                <section>
                    <OmFormInputFieldWrapper
                        id="spouseIdNumber"
                        isSensitive
                        required
                        keyboardMode="numeric"
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="South African ID number"
                        validationDelay={0}
                        value={spouseIdNumber.value}
                        state={spouseIdNumberInput?.status}
                        errorMessage={spouseIdNumberInput?.error}
                        onPersist={event => dispatch(setSpouseIdNumberValue(event.detail))}
                        onInputBlur={() => setSpouseIdNumberInput(spouseIdNumber)}
                        onFocus={() => setSpouseIdNumberInput(null)}
                        hintText="E.g. 790210111111"
                    />
                </section>
            }
            {isMarriedInCommunityOfProperty && !spouseIdentityType &&
                <section>
                    <OmFormInputFieldWrapper
                        id="spousePassportNumber"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="Passport Number"
                        validationDelay={0}
                        value={spousePassportNumber.value}
                        state={spousePassportNumberInput?.status}
                        errorMessage={spousePassportNumberInput?.error}
                        onPersist={event => dispatch(setSpousePassportNumberValue(event.detail))}
                        onInputBlur={() => setSpousePassportNumberInput(spousePassportNumber)}
                        onFocus={() => setSpousePassportNumberInput(null)}
                    />
                </section>
            }
        </>
    )
}

export default MarriageDetailsSection;

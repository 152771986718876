import { createSelector } from "reselect";
import { getTaxFreeSavingsAccount } from "./index";

export const getSessionCamVariables = createSelector(getTaxFreeSavingsAccount, tfsa => tfsa.sessionCamVariables);

export const getRequestId = createSelector(
    getSessionCamVariables,
    sessionCamVariables => sessionCamVariables ? sessionCamVariables.requestId : ''
);


import React from 'react';
import { OmApplicationCoverCategoryCard, OmIcon } from '@om/component-library-react';

// Children is used for passing in different button types
const ApplicationCoverCategoryCard = ({
    categoryCardSlot,
    imageUrl,
    title,
    description,
    children
}) => {

    return (
        <OmApplicationCoverCategoryCard slot={categoryCardSlot}>
            { imageUrl &&
                <OmIcon slot="icon" imageUrl={imageUrl}/>
            }
            <span slot="title">{title}</span>
            <span slot="description">{description}</span>
            <span slot="button">
                {children}
            </span>
        </OmApplicationCoverCategoryCard>
    );
};

export default ApplicationCoverCategoryCard;

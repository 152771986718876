import { OmFormDropdownFieldWrapper, OmFormInputFieldWrapper } from '@om/component-library-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    setMinorFirstNamesValue,
    setMinorIdValue,
    setMinorSurnameValue,
    setMinorTitleValue,
} from '@om/redux/actions/taxFreeSavingsAccount/auxiliaryDetails';
import {
    getMinorFirstNames,
    getMinorId,
    getMinorSurname,
    getMinorTitle,
} from '@om/redux/selectors/taxFreeSavingsAccount/auxiliaryDetails';
import { getReferenceTitles } from '@om/redux/selectors/taxFreeSavingsAccount/referenceData';

import { getSavingForMinor } from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupInitial';

const MinorDetailsSection = () => {
    const [minorFirstNamesInput, setMinorFirstNamesInput] = useState();
    const [minorSurnameInput, setMinorSurnameInput] = useState();
    const [minorIdInput, setMinorIdInput] = useState();
    const dispatch = useDispatch();
    const {
        minorTitle,
        minorFirstNames,
        minorSurname,
        minorIdNumber,
        isInvestingForMinor,

        referenceTitles,
    } = useSelector(
        createStructuredSelector({
            minorTitle: getMinorTitle,
            minorFirstNames: getMinorFirstNames,
            minorSurname: getMinorSurname,
            minorIdNumber: getMinorId,
            isInvestingForMinor: getSavingForMinor,

            referenceTitles: getReferenceTitles,
        })
    );

    return (
        <>
            {isInvestingForMinor && (
                <section>
                    <h6 className="medium no-bottom-margin">Details of the minor</h6>
                    <OmFormDropdownFieldWrapper
                        id="minorTitle"
                        useNativeMobileDropdown
                        required
                        size="medium"
                        formId="personalDetailsForm"
                        placeholder="Title"
                        name="title"
                        align="left"
                        value={minorTitle.value}
                        state={minorTitle.status}
                        errorMessage={minorTitle.error}
                        options={
                            referenceTitles &&
                            JSON.stringify(
                                referenceTitles.map((option) => ({ value: option.code, label: option.description }))
                            )
                        }
                        onOnSelect={(event) => dispatch(setMinorTitleValue(event.detail.id))}
                    />
                    <OmFormInputFieldWrapper
                        id="minorFirstNames"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="First name(s)"
                        validationDelay={0}
                        value={minorFirstNames.value}
                        state={minorFirstNamesInput?.status}
                        errorMessage={minorFirstNamesInput?.error}
                        onPersist={(event) => dispatch(setMinorFirstNamesValue(event.detail))}
                        onInputBlur={() => setMinorFirstNamesInput(minorFirstNames)}
                        onFocus={() => setMinorFirstNamesInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="minorSurname"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="Surname"
                        validationDelay={0}
                        value={minorSurname.value}
                        state={minorSurnameInput?.status}
                        errorMessage={minorSurnameInput?.error}
                        onPersist={(event) => dispatch(setMinorSurnameValue(event.detail))}
                        onInputBlur={() => setMinorSurnameInput(minorSurname)}
                        onFocus={() => setMinorSurnameInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="minorId"
                        isSensitive
                        required
                        keyboardMode="numeric"
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="South African ID number"
                        validationDelay={0}
                        value={minorIdNumber.value}
                        state={minorIdInput?.status}
                        errorMessage={minorIdInput?.error}
                        onPersist={(event) => dispatch(setMinorIdValue(event.detail))}
                        onInputBlur={() => setMinorIdInput(minorIdNumber)}
                        onFocus={() => setMinorIdInput(null)}
                        hintText="E.g. 790210111111"
                    />
                </section>
            )}
        </>
    );
};

export default MinorDetailsSection;

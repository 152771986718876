import React from 'react';
import { graphql } from 'gatsby';
import { OmRefinedProductCard } from '@om/component-library-react';
import './RefinedProductCard.scss';

function  productCardHeader(product_card_header) {
    if (product_card_header != null) {
        let returnText = product_card_header;
        if (returnText.length > 500) {
            const validText = returnText.slice(0, 500);
            const lastSpaceIndex = validText.lastIndexOf(' ');
            returnText = validText.slice(0, (lastSpaceIndex));
            returnText += '..';
            return returnText;
        } else {
            return returnText;
        }
    }
}

const RefinedProductCard = ({
    refinedProductCardContent,
    isLandscape,
    isExtended,
    slot,
    dataId,
    className
}) => {
    if (!refinedProductCardContent) {
        return '';
    }

    const {
        image_url,
        image_alt,
        product_card_category,
        product_card_header,
        product_card_copy,
        primary_button_text,
        primary_button_url,
        primary_button_action_type,
        secondary_button_text,
        secondary_button_url,
        secondary_button_action_type,
        call_me_back,
        call_me_back_secondary,
        open_tab,
        roa_quote_tool,
    } = refinedProductCardContent;



    return (
        <OmRefinedProductCard
            slot={slot}
            className={className}
            productCardCategory={product_card_category}
            productCardHeader={product_card_header ? productCardHeader(product_card_header) : ''}
            productCardCopy={product_card_copy}
            primaryButtonText={primary_button_text}
            primaryButtonUrl={primary_button_url ? primary_button_url.href : ''}
            primaryButtonActionType={primary_button_action_type}
            primaryButtonCallMeBackObject={call_me_back ? JSON.stringify(call_me_back) : ''}
            secondaryButtonText={secondary_button_text}
            secondaryButtonUrl={secondary_button_url ? secondary_button_url.href : ''}
            secondaryButtonActionType={secondary_button_action_type}
            secondaryButtonCallMeBackObject={call_me_back_secondary ? JSON.stringify(call_me_back_secondary) : ''}
            openTab={open_tab}
            dataId={dataId ? dataId : ''}
            isLandscape={isLandscape}
            isExtended={isExtended}
            primaryButtonRoaQuoteToolObject={roa_quote_tool ? JSON.stringify(roa_quote_tool[0]) : ''}
        >
        {
            image_url && image_url.gatsbyImageData ? (
                <picture slot="product-image">
                    <source type={image_url.gatsbyImageData.images.sources[0].type} srcSet={image_url.gatsbyImageData.images.sources[0].srcSet} />
                    <img src={image_url ? image_url?.gatsbyImageData.images.fallback?.src : image_url.url} decoding='async' loading='lazy'/>
                </picture>) : <div slot="product-image" style={{backgroundImage: `url('${image_url?.url}')`}} />
        }
        </OmRefinedProductCard>
    );
};

export const pageQuery = graphql`
fragment RefinedProductCard on Contentstack_refined_product_card {
    uid
    image_url {
        gatsbyImageData(
            height: 146
            formats: [AUTO, WEBP]
            quality: 100
            layout: FIXED
        )
        url
    }
    image_alt
    image_title
    product_card_category
    product_card_header
    product_card_copy
    primary_button_text
    primary_button_url {
    	...Link
    }
    primary_button_action_type
    secondary_button_text
    secondary_button_url {
    	...Link
    }
    secondary_button_action_type

    call_me_back {
        ...CallMeBack
    }
    call_me_back_secondary {
        ...CallMeBack
    }
    roa_quote_tool {
        ...RoaQuoteTool
    }
    open_tab
}
`;

export default RefinedProductCard

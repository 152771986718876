import React from 'react';
import {OmIcon} from '@om/component-library-react'

const NoResultsView = () => {
    return (
        <div className="no-results-view">
            <OmIcon
                imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltc34059ea0ce7dbb6/60828fa2d218710d6c8cc6e9/sentiment_dissatisfied.svg"
            />
            <h5 className="medium no-bottom-margin">
                No funds to show
            </h5>
            <p>
                Please update your search criteria
            </p>
        </div>
    );
};

export default NoResultsView;

import React from 'react';
import {useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
    getSearchAndFilterResultFunds,
    getSelectedFunds
} from '@om/redux/selectors/taxFreeSavingsAccount/advancedFundPicker';
import NoResultsView from './NoResultsView';
import FundCheckboxList from '../fundFiltering/FundCheckboxList';

const SearchResultsFundsView = () => {
    const {
        allSearchAndFilterResultFunds,
        selectedFunds,
    } = useSelector(
        createStructuredSelector({
            allSearchAndFilterResultFunds: getSearchAndFilterResultFunds,
            selectedFunds: getSelectedFunds,
        })
    );

    return (
        <div className="search-results-view">
            {allSearchAndFilterResultFunds.length > 0
                ? <div className="default-funds-wrapper">
                    <FundCheckboxList
                        fundList={allSearchAndFilterResultFunds}
                        selectedFunds={selectedFunds.value}
                    />
                </div>
                : <NoResultsView />
            }
        </div>
    );
};

export default SearchResultsFundsView;

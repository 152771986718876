import React from 'react';
import { OmAccordionItem } from '@om/component-library-react';
import {
    getSelectedFundListWithFees,
} from '@om/redux/selectors/taxFreeSavingsAccount/advancedFundPicker';
import { useSelector } from 'react-redux';

const FeesAndChargesAccordian = () => {
    const selectedFundListWithFees = useSelector(getSelectedFundListWithFees);

    return (
        <OmAccordionItem
            buttonText='Fees, Charges and Boosters'
            displayMode='card'
            expanded='false'
            hasSeparator='true'
        >
            <span slot='panel-content'>
                <div>

                    <p className="no-bottom-margin">
                        <strong>Asset management fees </strong>
                    </p>
                    <table className='fees-table'>
                        <thead>
                            <tr>
                                <th className='left-column'>
                                    <p className='medium'>
                                        <strong>UNDERLYING FUND</strong>
                                    </p>
                                </th>
                                <th>
                                    <p className='medium'>
                                        <strong>Asset Management Fees</strong>
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { selectedFundListWithFees.map((f, i) => (
                                f && <tr key={i}>
                                    <td className='left-column'>
                                        <p className='medium'>{f.name}</p>
                                    </td>
                                    <td className='column-2'>
                                        <p className='medium'>{f.fees}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <p className="no-bottom-margin">
                        <strong>Administration charges </strong>
                    </p>
                    <table className='fees-table'>
                        <thead>
                            <tr>
                                <th className='left-column'>
                                    <p className='medium'>
                                        <strong>ADMINISTRATION CHARGE</strong>
                                    </p>
                                </th>
                                <th>
                                    <p className='medium'>
                                        <strong>ADMINISTRATION REFUND</strong>
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='left-column'>
                                    <p className='medium'>0.5% yearly</p>
                                </td>
                                <td className='column-2'>
                                    <p className='medium'>50% of the administration charge</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <p>
                        <strong>EXPLANATION OF TERMS USED</strong>
                    </p>
                    <p className="medium">ASSET MANAGEMENT FEES</p>
                    <p>
                        Asset management fees are deducted from the value of your savings by the fund
                        managers of the underlying investment funds that you choose. Details of these
                        fees, as well as the manner in which the fund managers will manage the funds
                        are explained in the fund fact sheets of the underlying investment funds. It is
                        important to read the fact sheets. There may be different fees applicable to the
                        offshore portion of funds with offshore exposure.
                    </p>

                    <p className="medium">ADMINISTRATION CHARGES</p>
                    <p>
                        An administration charge of 0.5% yearly of your fund value will be charged.
                        This will be deducted at the end of every month. If you do not have a regular
                        investment set up on any of your Old Mutual Invest Plans (i.e. a Tax Free
                        Plan and/or Flexible Plan), or if your regular investment is cancelled, the
                        administration charge will be a minimum of R24.75 per month. This minimum
                        charge may be increased yearly with inflation as determined by Old Mutual.
                    </p>

                    <p className="medium">REFUND OF ADMINISTRATION CHARGE</p>
                    <p>
                        At the end of each tax year, we will check whether you have invested at least
                        R33 000, in that tax year, across any of your Old Mutual Invest Plans (i.e. Tax
                        Free Plan and/or Flexible Plan), or whether you have a total fund value of at
                        least R1 000 000, across your Old Mutual Invest Plans. If you have met either of
                        these criteria, we will <strong>refund</strong> up to half of the administration charges you paid
                        during that period. The refund will be paid proportionally into the underlying
                        investment funds in your Plan. Old Mutual may increase the amount needed to qualify
                        for this refund at its discretion.
                    </p>
                </div>
            </span>
        </OmAccordionItem>
    );
};

export default FeesAndChargesAccordian;

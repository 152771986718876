import { useEffect } from 'react';

export function useSessionCamVariables(requestId) {
    useEffect(() => {
        // Request ID has been updated
        if (!window.sessioncamConfiguration) {
            window.sessioncamConfiguration = {};
        }

        if (!window.sessioncamConfiguration.customDataObjects) {
            window.sessioncamConfiguration.customDataObjects = [];
        }

        const item = {
            key: 'requestId',
            value: requestId
        };

        window.sessioncamConfiguration.customDataObjects.push(item);
    }, [requestId])

}

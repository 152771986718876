import React from 'react';
import { OmApplicationCoverCategories  } from '@om/component-library-react';

const ApplicationCoverCategories = ({id, children}) => {
    return (
        <OmApplicationCoverCategories id={id}>
            {children}
        </OmApplicationCoverCategories>
    );
};

export default ApplicationCoverCategories;

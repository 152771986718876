import {
    OmApplicationCard,
    OmButtonSelector,
    OmFormInputFieldWrapper,
    OmPopUpMessage,
    OmRadioButtonGroup,
} from '@om/component-library-react';
import {
    setHasOtherTaxFreePlanValue,
    setLegalGuardianValue,
    setOnBehalfOfChildValue,
    setSavingForValue,
    setYourGoalValue,
} from '@om/redux/actions/taxFreeSavingsAccount/investmentSetupInitial';
import { setLandingPage } from '@om/redux/actions/taxFreeSavingsAccount/routing';
import { GUARDIAN_TYPES, INVESTMENT_SETUP_INITIAL_SAVING_FOR } from '@om/redux/actions/taxFreeSavingsAccount/types';
import { FORM_STATUS } from '@om/redux/reducers/status';
import {
    getAlternativeSolutionsProgressiveDisclosureIsValid,
    getHasOtherTaxFreePlan,
    getLegalGuardian,
    getOnBehalfOfChild,
    getOtherPlanDisclosureIsValid,
    getSavingFor,
    getSavingForProgressiveDisclosureIsValid,
    getYourGoal,
} from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupInitial';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RefinedProductCard from '../../../components/RefinedProductCard';
import ApplicationCoverCategories from '../../../components/applications/ApplicationCoverCategories';
import ApplicationCoverCategoryCard from '../../../components/applications/ApplicationCoverCategoryCard';
import './InvestmentSetupInitialPage.scss';

const getHasOtherPlanText = (isOnBehalfOfChild) => {
    return isOnBehalfOfChild === 'true' ? (
        <div slot="card-content">
            <h6 className="no-top-margin no-bottom-margin">Only one Tax Free Plan allowed per person</h6>
            <p>
                <small>
                    This is so that we can efficiently monitor contributions to ensure that the child's annual (or
                    lifetime) limit is not exceeded.
                </small>
            </p>
        </div>
    ) : (
        <div slot="card-content">
            <h6 className="no-top-margin no-bottom-margin">Only one Tax Free Plan allowed per person</h6>
            <p>
                <small>
                    This is so that we can efficiently monitor contributions to ensure that your annual (or lifetime)
                    limit is not exceeded.
                </small>
            </p>
        </div>
    );
};

const getInvestingForChildText = () => {
    return (
        <div slot="card-content">
            <p>
                <small>
                    As the parent/legal guardian, <strong>you do not own the plan</strong>, the child does. It will,
                    therefore, not show as part of your portfolio. You will be able to make changes to the Plan until
                    the child reaches the age of 18.{' '}
                </small>
            </p>
        </div>
    );
};

const getParentOrLegalGuardianText = (isLegalGuardian) => {
    return (
        <div slot="card-content">
            <h6 className="no-bottom-margin no-top-margin">What you’ll need</h6>
            <ul>
                <li>
                    <p className="no-bottom-margin">
                        <small>Childs proof of identity</small>
                    </p>
                    <h6 className="no-bottom-margin no-top-margin">
                        <small>
                            You can upload either the child’s abridged/unabridged birth certificate, South African ID,
                            or passport.
                        </small>
                    </h6>
                </li>
                {isLegalGuardian === GUARDIAN_TYPES.LEGAL_GUARDIAN ? (
                    <li>
                        <p>
                            <small>As a legal guardian, you need a court mandate</small>
                        </p>
                    </li>
                ) : (
                    ''
                )}
            </ul>
        </div>
    );
};

const getInvesmentLimitText = () => {
    const currentDate = new Date();
    const cutoffDate = new Date('26 February 2024');
    if (currentDate < cutoffDate) {
        return (
            <div slot="card-content">
                <p>
                    <small>
                        You can only <strong>invest up to R36 000 </strong>
                        in a tax-free plan per tax year and up to <strong>R500 000 in your lifetime. </strong>
                        Please keep your investment amount within the limit to avoid tax penalties.
                    </small>
                </p>
            </div>
        );
    }
    return (
        <div slot="card-content">
            <p>
                <small>
                    You can only <strong>invest up to R36 000 </strong>
                    in a tax-free plan per tax year and up to <strong>R500 000 in your lifetime. </strong>
                    Please keep your investment amount within the limit to avoid tax penalties.
                </small>
            </p>
        </div>
    );
};

const InvestmentSetupInitialPage = ({ investmentSetupInitialProductCards, landingPage }) => {
    const [yourGoalInput, setYourGoalInput] = useState();
    const dispatch = useDispatch();
    const {
        onBehalfOfChild,
        hasOtherTaxFreePlan,
        legalGuardian,
        savingFor,
        yourGoal,
        otherPlanDisclosureIsValid,
        savingForProgressiveDisclosureIsValid,
        alternativeSolutionsProgressiveDisclosureIsValid,
    } = useSelector(
        createStructuredSelector({
            onBehalfOfChild: getOnBehalfOfChild,
            hasOtherTaxFreePlan: getHasOtherTaxFreePlan,
            legalGuardian: getLegalGuardian,
            savingFor: getSavingFor,
            yourGoal: getYourGoal,
            otherPlanDisclosureIsValid: getOtherPlanDisclosureIsValid,
            savingForProgressiveDisclosureIsValid: getSavingForProgressiveDisclosureIsValid,
            alternativeSolutionsProgressiveDisclosureIsValid: getAlternativeSolutionsProgressiveDisclosureIsValid,
        })
    );

    useEffect(() => {
        dispatch(setLandingPage(landingPage));
    }, [dispatch, landingPage]);

    return (
        <div className="tfsa-investment-setup-initial-page">
            <div className="tfsa-on-behalf-of-child-wrapper">
                <h5 className="margin-left-h4 tfsa-mobile-wrapper-margin-top-bottom-0px">
                    Help us understand your investment needs by answering{' '}
                    <strong>
                        three easy <br /> questions
                    </strong>
                </h5>
                <h5 className="no-bottom-margin">Are you investing on behalf of a child?</h5>
                <OmRadioButtonGroup
                    id="onBehalfOfChild"
                    groupName="onBehalfOfChildGroup"
                    vertical={true}
                    value={onBehalfOfChild.value}
                    onOnSelection={(event) => dispatch(setOnBehalfOfChildValue(event.detail))}
                >
                    <ul slot="list-items">
                        <li value={true}>Yes</li>
                        <li value={false}>No</li>
                    </ul>
                </OmRadioButtonGroup>
                <OmPopUpMessage
                    message={onBehalfOfChild.error}
                    align="left"
                    mobileAlign="left"
                    messageWidth="160"
                    displayArrow={false}
                />
            </div>

            {onBehalfOfChild.value && onBehalfOfChild.value === 'true' ? (
                <div className="tfsa-legal-guardian-wrapper">
                    <h5 className="no-bottom-margin">Are you the parent or legal guardian of the child?</h5>

                    <OmRadioButtonGroup
                        id="legalGuardian"
                        groupName="parentOrGuardianGroup"
                        vertical={true}
                        value={legalGuardian.value}
                        onOnSelection={(event) => dispatch(setLegalGuardianValue(event.detail))}
                    >
                        <ul slot="list-items">
                            <li value={GUARDIAN_TYPES.PARENT}>{GUARDIAN_TYPES.PARENT}</li>
                            <li value={GUARDIAN_TYPES.LEGAL_GUARDIAN}>{GUARDIAN_TYPES.LEGAL_GUARDIAN}</li>
                        </ul>
                    </OmRadioButtonGroup>
                    <br />
                    <OmPopUpMessage
                        message={legalGuardian.error}
                        align="left"
                        mobileAlign="left"
                        messageWidth="160"
                        displayArrow={false}
                    />

                    {
                        //Only display this card before user makes parent/guardian choice
                        legalGuardian.value === null && (
                            <OmApplicationCard>{getInvestingForChildText()}</OmApplicationCard>
                        )
                    }

                    {
                        //When user selects legal Guardian display extra bullet point
                        legalGuardian.value && (
                            <OmApplicationCard>{getParentOrLegalGuardianText(legalGuardian.value)}</OmApplicationCard>
                        )
                    }
                </div>
            ) : (
                ''
            )}

            {otherPlanDisclosureIsValid && (
                <div className="tfsa-other-plan-group-wrapper">
                    <h5 className="no-bottom-margin">
                        {onBehalfOfChild.value && onBehalfOfChild.value === 'true'
                            ? 'Does the child have any other tax free plan with Old Mutual?'
                            : 'Do you have any other tax free plan with Old Mutual?'}
                    </h5>
                    <OmRadioButtonGroup
                        id="hasOtherTaxFreePlan"
                        groupName="otherPlanGroup"
                        vertical={true}
                        value={hasOtherTaxFreePlan.value}
                        onOnSelection={(event) => dispatch(setHasOtherTaxFreePlanValue(event.detail))}
                    >
                        <ul slot="list-items">
                            <li value={true}>Yes</li>
                            <li value={false}>No</li>
                        </ul>
                    </OmRadioButtonGroup>
                    <br />
                    <OmPopUpMessage
                        message={hasOtherTaxFreePlan.error}
                        align="left"
                        mobileAlign="left"
                        messageWidth="160"
                        displayArrow={false}
                    />

                    <OmApplicationCard>
                        {hasOtherTaxFreePlan.value === 'true'
                            ? getHasOtherPlanText(onBehalfOfChild.value)
                            : getInvesmentLimitText()}
                    </OmApplicationCard>
                </div>
            )}

            {alternativeSolutionsProgressiveDisclosureIsValid ? (
                <div className="tfsa-alternative-solutions-group-wrapper">
                    <h5 className="no-bottom-margin">
                        Here are other <strong>solutions for you</strong>
                    </h5>
                    <h6 className="no-bottom-margin">Top up your existing tax free savings or open a new account.</h6>
                    <div className="product-card-container" id="alternativeSolutions">
                        {investmentSetupInitialProductCards
                            ? investmentSetupInitialProductCards.map((card, index) => {
                                  return <RefinedProductCard refinedProductCardContent={card} key={index} />;
                              })
                            : ''}
                    </div>
                </div>
            ) : (
                ''
            )}

            {savingForProgressiveDisclosureIsValid ? (
                <div className="tfsa-saving-for-group-wrapper">
                    <h5 className="no-bottom-margin">
                        What are you <strong>saving for?</strong>
                    </h5>
                    <ApplicationCoverCategories id="savingFor">
                        <ApplicationCoverCategoryCard
                            categoryCardSlot="first"
                            imageUrl="https://eu-images.contentstack.com/v3/assets/blt086a07f2aa4a469d/blt5d5e159d1b00492d/60422302ddb5950b279d64d9/Save.svg"
                            title={INVESTMENT_SETUP_INITIAL_SAVING_FOR.GENERAL_SAVINGS}
                        >
                            <OmButtonSelector
                                value={INVESTMENT_SETUP_INITIAL_SAVING_FOR.GENERAL_SAVINGS}
                                text="select"
                                selectorGroup="savingFor"
                                hasCheck
                                selected={savingFor.value === INVESTMENT_SETUP_INITIAL_SAVING_FOR.GENERAL_SAVINGS}
                                onClick={() =>
                                    dispatch(setSavingForValue(INVESTMENT_SETUP_INITIAL_SAVING_FOR.GENERAL_SAVINGS))
                                }
                            />
                        </ApplicationCoverCategoryCard>
                        <ApplicationCoverCategoryCard
                            categoryCardSlot="second"
                            imageUrl="https://eu-images.contentstack.com/v3/assets/blt086a07f2aa4a469d/blt9077349cf6674497/60422302bda0ad165f9c19bc/Vacay.svg"
                            title={INVESTMENT_SETUP_INITIAL_SAVING_FOR.RETIREMENT}
                        >
                            <OmButtonSelector
                                text="select"
                                selectorGroup="savingFor"
                                hasCheck
                                value={INVESTMENT_SETUP_INITIAL_SAVING_FOR.RETIREMENT}
                                selected={savingFor.value === INVESTMENT_SETUP_INITIAL_SAVING_FOR.RETIREMENT}
                                onClick={() =>
                                    dispatch(setSavingForValue(INVESTMENT_SETUP_INITIAL_SAVING_FOR.RETIREMENT))
                                }
                            />
                        </ApplicationCoverCategoryCard>
                        <ApplicationCoverCategoryCard
                            categoryCardSlot="third"
                            imageUrl="https://eu-images.contentstack.com/v3/assets/blt086a07f2aa4a469d/bltf06fadda2c7caceb/60422302804b990882312bfa/Educate.svg"
                            title={INVESTMENT_SETUP_INITIAL_SAVING_FOR.EDUCATION}
                        >
                            <OmButtonSelector
                                text="select"
                                selectorGroup="savingFor"
                                hasCheck
                                value={INVESTMENT_SETUP_INITIAL_SAVING_FOR.EDUCATION}
                                selected={savingFor.value === INVESTMENT_SETUP_INITIAL_SAVING_FOR.EDUCATION}
                                onClick={() =>
                                    dispatch(setSavingForValue(INVESTMENT_SETUP_INITIAL_SAVING_FOR.EDUCATION))
                                }
                            />
                        </ApplicationCoverCategoryCard>
                    </ApplicationCoverCategories>
                    <br />
                    <OmPopUpMessage
                        message={savingFor.error}
                        align="left"
                        mobileAlign="left"
                        messageWidth="160"
                        displayArrow={false}
                    />
                    {savingFor.status === FORM_STATUS.VALID ? (
                        <OmFormInputFieldWrapper
                            id="yourGoal"
                            placeholder="Name your goal"
                            placeholderAsLabel
                            required={false}
                            size="full"
                            validationDelay={0}
                            value={yourGoal.value}
                            state={yourGoalInput?.status}
                            errorMessage={yourGoalInput?.error}
                            onPersist={(event) => dispatch(setYourGoalValue(event.detail))}
                            onInputBlur={() => setYourGoalInput(yourGoal)}
                            onFocus={() => setYourGoalInput(null)}
                        />
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default InvestmentSetupInitialPage;

import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
    OmApplicationRadioButtonAccordionGroup,
    OmFormError,
    OmApplicationRadioButtonAccordionItem,
    OmFormDropdownFieldWrapper,
    OmIcon,
} from '@om/component-library-react';

import { viewMoreToggle } from '@om/redux/actions/taxFreeSavingsAccount/investmentSetupContribution';
import {
    setOwnPercentageValue,
    setPremiumEscalationValue
} from '@om/redux/actions/taxFreeSavingsAccount/investmentSetupContribution';
import {
    getIsViewMoreExpanded,
    getPremiumEscalation,
    getOwnPercentage,
    getIsMaxContributionEntered,
    getDisplayLinkWithEducationOption,
    getDisplayLinkWithSalaryOption,
    getIsMinimumMonthlyContributionEntered
} from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupContribution';
import { PREMIUM_ESCALATION_OPTIONS } from '@om/redux/actions/taxFreeSavingsAccount/types';

const PremiumEscalationAccordionGroup = () => {
    const dispatch = useDispatch();
    const {
        premiumEscalation,
        isViewMoreExpanded,
        displayLinkWithSalaryOption,
        displayLinkWithEducationOption,
        maxContributionEntered,
        ownPercentage,
        minimumMonthlyContributionEntered
    } = useSelector(
        createStructuredSelector({
            premiumEscalation: getPremiumEscalation,
            isViewMoreExpanded: getIsViewMoreExpanded,
            ownPercentage: getOwnPercentage,
            maxContributionEntered: getIsMaxContributionEntered,
            displayLinkWithEducationOption: getDisplayLinkWithEducationOption,
            displayLinkWithSalaryOption: getDisplayLinkWithSalaryOption,
            minimumMonthlyContributionEntered: getIsMinimumMonthlyContributionEntered
        })
    );

    const percentageArray = () => {
        return Array(20).fill().map((_, count) => 1 + count);
    };

    const radioAccordionItems = [
        !maxContributionEntered &&
        <OmApplicationRadioButtonAccordionItem
            key={PREMIUM_ESCALATION_OPTIONS.LINK_WITH_INFLATION.key}
            onOnChange={() => dispatch(setPremiumEscalationValue(PREMIUM_ESCALATION_OPTIONS.LINK_WITH_INFLATION.key))}
            buttonGroupName="premiumEscalation"
            radioButtonId={PREMIUM_ESCALATION_OPTIONS.LINK_WITH_INFLATION.key}
            radioButtonTitle={PREMIUM_ESCALATION_OPTIONS.LINK_WITH_INFLATION.title}
            description={PREMIUM_ESCALATION_OPTIONS.LINK_WITH_INFLATION.description}>
        </OmApplicationRadioButtonAccordionItem>,
        displayLinkWithSalaryOption &&
        <OmApplicationRadioButtonAccordionItem
            key={PREMIUM_ESCALATION_OPTIONS.LINK_WITH_SALARY.key}
            onOnChange={() => dispatch(setPremiumEscalationValue(PREMIUM_ESCALATION_OPTIONS.LINK_WITH_SALARY.key))}
            buttonGroupName="premiumEscalation"
            radioButtonId={PREMIUM_ESCALATION_OPTIONS.LINK_WITH_SALARY.key}
            radioButtonTitle={PREMIUM_ESCALATION_OPTIONS.LINK_WITH_SALARY.title}
            description={PREMIUM_ESCALATION_OPTIONS.LINK_WITH_SALARY.description}>
        </OmApplicationRadioButtonAccordionItem>,
        displayLinkWithEducationOption &&
        <OmApplicationRadioButtonAccordionItem
            key={PREMIUM_ESCALATION_OPTIONS.EDUCATION.key}
            onOnChange={() => dispatch(setPremiumEscalationValue(PREMIUM_ESCALATION_OPTIONS.EDUCATION.key))}
            buttonGroupName="premiumEscalation"
            radioButtonId={PREMIUM_ESCALATION_OPTIONS.EDUCATION.key}
            radioButtonTitle={PREMIUM_ESCALATION_OPTIONS.EDUCATION.title}
            description={PREMIUM_ESCALATION_OPTIONS.EDUCATION.description}>
        </OmApplicationRadioButtonAccordionItem>,
        !maxContributionEntered && !minimumMonthlyContributionEntered &&
        <OmApplicationRadioButtonAccordionItem
            key={PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.key}
            onOnChange={() => dispatch(setPremiumEscalationValue(PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.key))}
            buttonGroupName="premiumEscalation"
            radioButtonId={PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.key}
            panelSelected={premiumEscalation.value === PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.key}
            radioButtonTitle={PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.title}
            description={PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.description}>
            <div slot="panel-content">
                <div className="percentage-panel-content">
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        required
                        id="ownPercentage"
                        size="full"
                        name="ownPercentage"
                        formId="investmentSetupForm"
                        placeholder="Select percentage"
                        placeholderAsLabel="false"
                        align="left"
                        value={ownPercentage.value}
                        state={ownPercentage.status}
                        errorMessage={ownPercentage.error}
                        options={ JSON.stringify(percentageArray().map(option => ({ value: option, label: option }))) }
                        onOnSelect={(event) => dispatch(setOwnPercentageValue(event.detail.id))}
                    />
                </div>
            </div>
        </OmApplicationRadioButtonAccordionItem>,
        maxContributionEntered && !minimumMonthlyContributionEntered &&
        <OmApplicationRadioButtonAccordionItem
            key={PREMIUM_ESCALATION_OPTIONS.ANNUAL_TAX_FREE_LIMIT.key}
            onOnChange={() => dispatch(setPremiumEscalationValue(PREMIUM_ESCALATION_OPTIONS.ANNUAL_TAX_FREE_LIMIT.key))}
            buttonGroupName="premiumEscalation"
            radioButtonId={PREMIUM_ESCALATION_OPTIONS.ANNUAL_TAX_FREE_LIMIT.key}
            radioButtonTitle={PREMIUM_ESCALATION_OPTIONS.ANNUAL_TAX_FREE_LIMIT.title}
            description={PREMIUM_ESCALATION_OPTIONS.ANNUAL_TAX_FREE_LIMIT.description}>
        </OmApplicationRadioButtonAccordionItem>,
        !minimumMonthlyContributionEntered &&
        <OmApplicationRadioButtonAccordionItem
            key={PREMIUM_ESCALATION_OPTIONS.NO_INCREASE.key}
            onOnChange={() => dispatch(setPremiumEscalationValue(PREMIUM_ESCALATION_OPTIONS.NO_INCREASE.key))}
            buttonGroupName="premiumEscalation"
            radioButtonId={PREMIUM_ESCALATION_OPTIONS.NO_INCREASE.key}
            radioButtonTitle={PREMIUM_ESCALATION_OPTIONS.NO_INCREASE.title}
            description={PREMIUM_ESCALATION_OPTIONS.NO_INCREASE.description}>
        </OmApplicationRadioButtonAccordionItem>
    ];

    const numberOfChildren = () => { return radioAccordionItems.filter(child => child !== false).length; };
    const getChildren = () => {
        if (!isViewMoreExpanded && numberOfChildren() > 3) {
            // Reduce children to only display first 3
            return radioAccordionItems.slice(0,4);
        }
        return radioAccordionItems;
    };

    return (
        <div className="radio-button-accordion-group-wrapper">
            <OmApplicationRadioButtonAccordionGroup selectedValue={premiumEscalation.value}>
                <div slot="radio-accordion-items">
                    { getChildren() }
                </div>
            </OmApplicationRadioButtonAccordionGroup>

            { (numberOfChildren() > 3) &&
            <div className="view-more-wrapper" onClick={() => dispatch(viewMoreToggle())}>
                <h6>{isViewMoreExpanded ? `View less` : `View more`}</h6>
                <OmIcon iconName="arrow_forward"/>
            </div>
            }
            <OmFormError message={premiumEscalation.error}></OmFormError>
        </div>
    );
};

export default PremiumEscalationAccordionGroup;
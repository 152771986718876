import React from 'react';
import {
    OmFcFilterResult,
    OmIcon,
    OmFcSearchBar,
} from '@om/component-library-react';
import OmButton from '../../../../components/forms/OmButton';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
    getSelectedFundFilterCarouselItems, getSelectedSearchQuery,
    getShowTextSearchBar, getSuggestedSearchFunds, getTempSearchQuery,
    getShowSearchResults
} from '@om/redux/selectors/taxFreeSavingsAccount/advancedFundPicker';
import {
    toggleShowTextSearchBar,
    toggleFilterOptionsDrawer,
    setTempSearchQuery,
    setSelectedSearchQuery,
    setShowSearchResults
} from '@om/redux/actions/taxFreeSavingsAccount/advancedFundPicker';
import './SearchBar.scss';

const ResponsiveFilterResult = ({children}) => {
    const className = 'om-tfsa-advanced-search-bar';
    return (
        <span className="responsive-filter-result">
            <span className="responsive-filter-result-mobile">
                <OmFcFilterResult isMobile className={className}>
                    {children}
                </OmFcFilterResult>
            </span>
            <span className="responsive-filter-result-desktop">
                <OmFcFilterResult isMobile={false} className={className}>
                    {children}
                </OmFcFilterResult>
            </span>
        </span>
    );
};

const SearchBar = () => {
 
    const dispatch = useDispatch();
    const {
        selectedFundFilterCarouselItems: filterChips,
        showTextSearchBar,
        suggestedSearchFunds,
        tempSearchQuery,
        showSearchResults
    } = useSelector(
        createStructuredSelector({
            selectedFundFilterCarouselItems: getSelectedFundFilterCarouselItems,
            showTextSearchBar: getShowTextSearchBar,
            suggestedSearchFunds: getSuggestedSearchFunds,
            tempSearchQuery: getTempSearchQuery,
            selectedSearchQuery: getSelectedSearchQuery,
            showSearchResults: getShowSearchResults
        })
    );

    const showRecommendedFunds = suggestedSearchFunds;
    const toggleFilterSideDrawer = () => dispatch(toggleFilterOptionsDrawer());
    const resetSearchBar = () => {
        dispatch(setTempSearchQuery(null));
        dispatch(setSelectedSearchQuery(null));
        dispatch(toggleShowTextSearchBar());
    };
    const doSearch = event => {
        dispatch(setTempSearchQuery(event.detail));
        dispatch(setShowSearchResults(false));
        dispatch(setSelectedSearchQuery(event.detail));
    };

    return (
        
        <ResponsiveFilterResult>
            <div slot="desktop-filter-criteria">
                <div className="filter-funds">
                    <OmIcon
                        iconName="filter"
                        id="filter-icon"
                        width="18"
                        height="18"
                        onClick={toggleFilterSideDrawer}
                    />

                    <OmButton
                        className={'filter-button-text'}
                        type="text"
                        text={filterChips && filterChips.length ? 'Update Filters' : 'Filter by Category'}
                        size="small"
                        onClick={toggleFilterSideDrawer}
                    />
              
                </div>

                <div className="search-bar tfsa-advanced-search-bar">
                    {!showTextSearchBar
                        ? <div onClick={resetSearchBar}>
                            <OmButton type="text" text="SEARCH" size="small" />
                            <OmIcon iconName="search" width="24px" height="24px" />
                        </div>

                        : <OmFcSearchBar
                            searchValue={tempSearchQuery}
                            onPersist={(event) => {
                                dispatch(setTempSearchQuery(event.detail));
                                dispatch(setShowSearchResults(true));
                            }}
                            onDoSearch={doSearch}
                            onResetSearch={resetSearchBar}
                        >
                            <div slot="recommended-options" className="recommended-options">
                                <ul>
                                    {showSearchResults && showRecommendedFunds && suggestedSearchFunds.map((suggestion, index) => (
                                        <li key={`tfsa-om-fc-search-bar-li-${index}`}>
                                            {suggestion.fundDetails.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </OmFcSearchBar>
                    }
                </div>
            </div>
            <div slot="mobile-filter-criteria">
                <div className="filter-funds">
                    <OmIcon
                        iconName="filter"
                        id="filter-icon"
                        width="18"
                        height="18"
                        onClick={toggleFilterSideDrawer}
                    />
                </div>

                <div className="search-bar tfsa-advanced-search-bar">
                    {!showTextSearchBar
                        ? <div onClick={resetSearchBar}>
                            <OmButton type="text" text="SEARCH" size="small" />
                            <OmIcon iconName="search" width="24" height="24" />
                        </div>

                        : <OmFcSearchBar
                            searchValue={tempSearchQuery}
                            onPersist={(event) => {
                                dispatch(setTempSearchQuery(event.detail));
                                dispatch(setShowSearchResults(true));
                            }}
                            onDoSearch={doSearch}
                            onResetSearch={resetSearchBar}
                        >
                            <div slot="recommended-options" className="recommended-options">
                                <ul>
                                    {showSearchResults && showRecommendedFunds && suggestedSearchFunds.map((suggestion, index) => (
                                        <li key={`tfsa-om-fc-search-bar-li-${index}`}>
                                            {suggestion.fundDetails.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </OmFcSearchBar>
                    }
                </div>
            </div>
        </ResponsiveFilterResult>
    );
};

export default SearchBar;
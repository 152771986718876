import React from 'react';
import OmButton from '../../../../components/forms/OmButton';
import {
    OmApplicationPopupModal,
    OmIcon
} from '@om/component-library-react';

const SuccessModal = ({ open, buttonHandler }) => {
    return (
        <OmApplicationPopupModal
            name="successModal"
            dissmisable
            open={open}
            onModalClosed={() => buttonHandler()}>
            <div slot="content">
                <OmIcon
                    imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt801513b7ef009be6/5f2935c8dfac843bffcdecfa/SUCCESS_CMOS.svg"
                />
                <h5 className="no-bottom-margin">All good to go!</h5>
            </div>
            <div slot="button">
                <OmButton
                    type='primary'
                    size='large'
                    text='Okay, thanks'
                    onClick={() => buttonHandler()}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default SuccessModal;
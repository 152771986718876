import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './InvestmentSetupContributionPage.scss';

import {
    OmApplicationCoverCategories,
    OmApplicationCoverCategoryCard,
    OmButtonSelector,
    OmFormInputFieldWrapper,
} from '@om/component-library-react';
import { CONTRIBUTION_TYPE } from '@om/redux/actions/taxFreeSavingsAccount/types';
import ErrorSelectionModal from './ErrorSelectionModal';
import LimitExceededModal from './LimitExceededModal';
import PremiumEscalationAccordionGroup from './PremiumEscalationAccordionGroup';
import ReferenceDataErrorModal from './ReferenceDataErrorModal';

import {
    limitExceededModalDisplayValue,
    resetContributionValue,
    setContributionValue,
    setLumpSumContributionValue,
    setRegularContributionValue,
} from '@om/redux/actions/taxFreeSavingsAccount/investmentSetupContribution';
import { getSidebarOpen } from '@om/redux/selectors/publicWeb/callMeBack';
import {
    getContributionType,
    getDispalyLimitExceededModal,
    getDisplayLinkWithEducationOption,
    getDisplayLinkWithSalaryOption,
    getDisplayLumpSumInput,
    getDisplayPremiumEscalation,
    getDisplayRegularInput,
    getIsMaxContributionEntered,
    getLumpSumContribution,
    getLumpSumHintText,
    getMinimumMonthlyContribution,
    getOwnPercentage,
    getRegularContribution,
} from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupContribution';
import { getIsErrorModalOpen } from '@om/redux/selectors/taxFreeSavingsAccount/referenceData';

const InvestmentSetupContributionPage = ({ callMeBack }) => {
    const dispatch = useDispatch();
    const [regularContributionInput, setRegularContributionInput] = useState();
    const [lumpSumContributionInput, setlumpSumContributionInput] = useState();
    const selectors = useSelector(
        createStructuredSelector({
            contributionType: getContributionType,
            lumpSumContribution: getLumpSumContribution,
            regularContribution: getRegularContribution,
            ownPercentage: getOwnPercentage,
            displayPremiumEscalation: getDisplayPremiumEscalation,
            displayRegularInput: getDisplayRegularInput,
            displayLumpSumInput: getDisplayLumpSumInput,
            maxContributionEntered: getIsMaxContributionEntered,
            displayLinkWithEducationOption: getDisplayLinkWithEducationOption,
            displayLinkWithSalaryOption: getDisplayLinkWithSalaryOption,
            lumpSumHintText: getLumpSumHintText,
            displayLimitExceededModal: getDispalyLimitExceededModal,
            referenceDataErrorModalOpen: getIsErrorModalOpen,
            cmbOpen: getSidebarOpen,
            minimumMonthlyContribution: getMinimumMonthlyContribution,
        })
    );

    return (
        <div className="tfsa-investment-setup-contribution-page">
            <div className="tfsa-investment-setup-contribution-page-wrapper">
                <h5 className="heading">
                    <strong>How would you like to save?</strong>
                </h5>
                <div className="contribution-selectors">
                    <OmApplicationCoverCategories>
                        <OmApplicationCoverCategoryCard slot="first">
                            <span slot="title">Monthly</span>
                            <span slot="description">Allows you to make a regular monthly contribution.</span>
                            <span slot="button">
                                <OmButtonSelector
                                    value="monthly"
                                    text={
                                        selectors.contributionType.value === CONTRIBUTION_TYPE.MONTHLY
                                            ? 'Selected'
                                            : 'Select'
                                    }
                                    selectorGroup="contributionType"
                                    hasCheck
                                    selected={selectors.contributionType.value === CONTRIBUTION_TYPE.MONTHLY}
                                    onClick={() => dispatch(setContributionValue(CONTRIBUTION_TYPE.MONTHLY))}
                                ></OmButtonSelector>
                            </span>
                        </OmApplicationCoverCategoryCard>
                        <OmApplicationCoverCategoryCard slot="second">
                            <span slot="title">Once off</span>
                            <span slot="description">Allows you to contribute a single lump-sum amount.</span>
                            <span slot="button">
                                <OmButtonSelector
                                    value="lumpSum"
                                    text={
                                        selectors.contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM
                                            ? 'Selected'
                                            : 'Select'
                                    }
                                    selectorGroup="contributionType"
                                    hasCheck
                                    selected={selectors.contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM}
                                    onClick={() => dispatch(setContributionValue(CONTRIBUTION_TYPE.LUMP_SUM))}
                                ></OmButtonSelector>
                            </span>
                        </OmApplicationCoverCategoryCard>
                        <OmApplicationCoverCategoryCard slot="third">
                            <span slot="title">Combination of both</span>
                            <span slot="description">Allows you to make monthly and once-off contributions.</span>
                            <span slot="button">
                                <OmButtonSelector
                                    value="both"
                                    text={
                                        selectors.contributionType.value === CONTRIBUTION_TYPE.COMBINATION
                                            ? 'Selected'
                                            : 'Select'
                                    }
                                    selectorGroup="contributionType"
                                    hasCheck
                                    selected={selectors.contributionType.value === CONTRIBUTION_TYPE.COMBINATION}
                                    onClick={() => dispatch(setContributionValue(CONTRIBUTION_TYPE.COMBINATION))}
                                ></OmButtonSelector>
                            </span>
                        </OmApplicationCoverCategoryCard>
                    </OmApplicationCoverCategories>
                </div>

                {selectors.displayLumpSumInput && (
                    <div className="lump-sum-container">
                        {selectors.contributionType.value === CONTRIBUTION_TYPE.COMBINATION && (
                            <h6>Lump-sum contribution</h6>
                        )}
                        <h5>
                            <strong>How much can you afford to save once-off?</strong>
                        </h5>
                        <div className="input-container">
                            <OmFormInputFieldWrapper
                                required
                                id="lumpSumContributionValue"
                                prefixString="R"
                                size="full"
                                align="left"
                                mask="thousands-spaced"
                                keyboardMode="numeric"
                                hintText={selectors.lumpSumHintText}
                                placeholderAsLabel="false"
                                validationDelay={0}
                                value={selectors.lumpSumContribution.value}
                                state={lumpSumContributionInput?.status}
                                errorMessage={lumpSumContributionInput?.error}
                                onPersist={(event) => dispatch(setLumpSumContributionValue(event.detail))}
                                onInputBlur={() => setlumpSumContributionInput(selectors.lumpSumContribution)}
                                onFocus={() => setlumpSumContributionInput(null)}
                            />
                        </div>
                    </div>
                )}

                {selectors.contributionType.value === CONTRIBUTION_TYPE.COMBINATION && <hr></hr>}

                {selectors.displayRegularInput && (
                    <div className="regular-contribution-container">
                        {selectors.contributionType.value === CONTRIBUTION_TYPE.COMBINATION && (
                            <h6>Regular contribution</h6>
                        )}
                        <h5>
                            <strong>How much can you afford to save monthly?</strong>
                        </h5>
                        <div className="input-container">
                            <OmFormInputFieldWrapper
                                required
                                id="regularContributionValue"
                                size="full"
                                align="left"
                                prefixString="R"
                                mask="thousands-spaced"
                                keyboardMode="numeric"
                                hintText={`Min R${selectors.minimumMonthlyContribution} - Max R3 000 p/m`}
                                placeholderAsLabel="false"
                                validationDelay={0}
                                value={selectors.regularContribution.value}
                                state={regularContributionInput?.status}
                                errorMessage={regularContributionInput?.error}
                                onPersist={(event) => dispatch(setRegularContributionValue(event.detail))}
                                onInputBlur={() => setRegularContributionInput(selectors.regularContribution)}
                                onFocus={() => setRegularContributionInput(null)}
                            />
                        </div>
                    </div>
                )}

                {selectors.displayPremiumEscalation && (
                    <div className="premium-escalation-container">
                        <h5 className="no-margin-bottom" id="premiumEscalation">
                            <strong>How would you like to increase the amount you save yearly?</strong>
                        </h5>
                        <h6>You have an option to change your selection later if you’d like to. </h6>
                        <PremiumEscalationAccordionGroup />
                    </div>
                )}

                <LimitExceededModal
                    open={selectors.displayLimitExceededModal}
                    buttonHandler={() => dispatch(limitExceededModalDisplayValue(false))}
                />

                <ErrorSelectionModal
                    id="contributionType"
                    open={!!selectors.contributionType.error}
                    closeHandler={() => dispatch(resetContributionValue())}
                />

                <ReferenceDataErrorModal
                    callMeBack={callMeBack}
                    open={selectors.referenceDataErrorModalOpen && !selectors.cmbOpen}
                />
            </div>
        </div>
    );
};

export default InvestmentSetupContributionPage;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './PaymentDetailsPage.scss';

import {
    OmApplicationTooltip,
    OmApplicationUploadCard,
    OmCheckBox,
    OmFormDropdownFieldWrapper,
    OmFormError,
    OmFormInputFieldWrapper,
    OmIcon,
    OmModalLoader,
    OmPopUpMessage,
    OmRadioButtonGroup,
} from '@om/component-library-react';
import {
    removeBirthCertificateValue,
    removeCourtMandateValue,
    removeIdentityDocumentValue,
    setAccountNumberValue,
    setAccountTypeValue,
    setAccountValidationReset,
    setAccountValidationStatus,
    setBankNameValue,
    setBirthCertificateValue,
    setCourtMandateValue,
    setDebitOrderDayValue,
    setEmploymentIndustryOtherValue,
    setEmploymentIndustryValue,
    setEmploymentPositionValue,
    setIdentityDocumentValue,
    setLumpSumDayValue,
    setLumpSumMonthValue,
    setMonthToSkipValue,
    setMonthValue,
    setSourceOfFundsValue,
    setSourceOfIncomeOtherValue,
    setSourceOfIncomeValue,
    toggleIncludeAvs,
    toggleIncludeCdv,
    togglePaymentAuthorization,
    toggleSkipMonth,
} from '@om/redux/actions/taxFreeSavingsAccount/paymentDetails';
import { ACCOUNT_VALIDATION_STATUS, CONTRIBUTION_TYPE } from '@om/redux/actions/taxFreeSavingsAccount/types';
import { getIsMinorSurnameSameParent } from '@om/redux/selectors/taxFreeSavingsAccount/auxiliaryDetails';
import { getContributionType } from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupContribution';
import {
    getIsLegalGuardian,
    getIsParent,
    getSavingForMinor,
} from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupInitial';
import {
    getAccountNumber,
    getAccountType,
    getAccountValidationStatus,
    getBankName,
    getBirthCertificateFileError,
    getBirthCertificateFileStatus,
    getBlurbText,
    getCalendarMonths,
    getCheckboxChecked,
    getCourtMandateFileError,
    getCourtMandateFileStatus,
    getDebitOrderDay,
    getDisplaySuccessModal,
    getEmploymentIndustry,
    getEmploymentIndustryOther,
    getEmploymentPosition,
    getIdentityDocumentFileError,
    getIdentityDocumentFileStatus,
    getIsEmploymentIndustryOtherRequired,
    getIsEmploymentIndustryRequired,
    getIsSourceOfIncomeOtherRequired,
    getLumpSumDay,
    getLumpSumMonth,
    getMonth,
    getMonthToSkip,
    getPaymentAuthorizationChecked,
    getPaymentAuthorizationError,
    getPaymentDetailsCompleted,
    getReferenceDebitOrderMonths,
    getReferenceLumpSumMonths,
    getSkipMonth,
    getSourceOfFunds,
    getSourceOfIncome,
    getSourceOfIncomeOther,
} from '@om/redux/selectors/taxFreeSavingsAccount/paymentDetails';
import { getFirstNames, getSurname } from '@om/redux/selectors/taxFreeSavingsAccount/personalDetails';
import {
    getReferenceAccountTypes,
    getReferenceBanks,
    getReferenceDebitOrderDays,
    getReferenceEmploymentIndustry,
    getReferenceEmploymentPosition,
    getReferenceSourceOfFunds,
    getReferenceSourceOfIncome,
} from '@om/redux/selectors/taxFreeSavingsAccount/referenceData';
import { DEFAULT_VALIDATION_DELAY } from '../../config';
import ErrorResponseModal from './popups/ErrorResponseModal';
import ServerErrorResponseModal from './popups/ServerErrorResponseModal';
import SuccessModal from './popups/SuccessModal';

const PaymentDetailsPage = ({ includeAvs, includeCdv }) => {
    const [accountNumberInput, setAccountNumberInput] = useState();
    const [sourceOfIncomeOtherInput, setSourceOfIncomeOtherInput] = useState();
    const [employmentIndustryOtherInput, setEmploymentIndustryOtherInput] = useState();
    const dispatch = useDispatch();
    const {
        bankName,
        name,
        surname,
        accountType,
        accountNumber,
        debitOrderDay,
        month,
        skipMonth,
        lumpSumDay,
        lumpSumMonth,
        monthToSkip,
        paymentDetailsCompleted,
        paymentAuthorizationError,
        paymentAuthorizationChecked,
        accountValidationStatus,
        calendarMonths,
        referenceBanks,
        referenceAccountTypes,
        referenceDebitOrderMonths,
        referenceDebitOrderDays,
        blurbText,
        contributionType,
        checkboxChecked,
        referenceLumpSumMonths,
        sourceOfFunds,
        sourceOfIncome,
        sourceOfIncomeOther,
        employmentIndustry,
        employmentIndustryOther,
        employmentPosition,
        referenceEmploymentIndustry,
        referenceEmploymentPosition,
        referenceSourceOfFunds,
        referenceSourceOfIncome,
        isSourceOfIncomeOtherRequired,
        isEmploymentIndustryOtherRequired,
        isEmploymentIndustryRequired,
        displaySuccessModal,
        savingForMinor,
        isLegalGuardian,
        isParent,
        isMinorAndParentSurnameSame,

        identityDocumentError,
        identityDocumentStatus,
        courtMandateError,
        courtMandateStatus,
        birthCertificateError,
        birthCertificateStatus,
    } = useSelector(
        createStructuredSelector({
            bankName: getBankName,
            name: getFirstNames,
            surname: getSurname,
            accountType: getAccountType,
            accountNumber: getAccountNumber,
            debitOrderDay: getDebitOrderDay,
            month: getMonth,
            skipMonth: getSkipMonth,
            lumpSumDay: getLumpSumDay,
            lumpSumMonth: getLumpSumMonth,
            monthToSkip: getMonthToSkip,
            paymentDetailsCompleted: getPaymentDetailsCompleted,
            paymentAuthorizationError: getPaymentAuthorizationError,
            paymentAuthorizationChecked: getPaymentAuthorizationChecked,
            accountValidationStatus: getAccountValidationStatus,
            calendarMonths: getCalendarMonths,

            referenceBanks: getReferenceBanks,
            referenceAccountTypes: getReferenceAccountTypes,
            referenceDebitOrderMonths: getReferenceDebitOrderMonths,
            referenceLumpSumMonths: getReferenceLumpSumMonths,
            referenceDebitOrderDays: getReferenceDebitOrderDays,
            referenceSourceOfIncome: getReferenceSourceOfIncome,
            referenceSourceOfFunds: getReferenceSourceOfFunds,
            referenceEmploymentPosition: getReferenceEmploymentPosition,
            referenceEmploymentIndustry: getReferenceEmploymentIndustry,

            blurbText: getBlurbText,
            contributionType: getContributionType,
            checkboxChecked: getCheckboxChecked,
            sourceOfFunds: getSourceOfFunds,
            sourceOfIncome: getSourceOfIncome,
            sourceOfIncomeOther: getSourceOfIncomeOther,
            employmentIndustry: getEmploymentIndustry,
            employmentIndustryOther: getEmploymentIndustryOther,
            employmentPosition: getEmploymentPosition,

            isSourceOfIncomeOtherRequired: getIsSourceOfIncomeOtherRequired,
            isEmploymentIndustryOtherRequired: getIsEmploymentIndustryOtherRequired,
            isEmploymentIndustryRequired: getIsEmploymentIndustryRequired,
            displaySuccessModal: getDisplaySuccessModal,
            savingForMinor: getSavingForMinor,
            isLegalGuardian: getIsLegalGuardian,
            isParent: getIsParent,
            isMinorAndParentSurnameSame: getIsMinorSurnameSameParent,

            identityDocumentError: getIdentityDocumentFileError,
            identityDocumentStatus: getIdentityDocumentFileStatus,
            courtMandateError: getCourtMandateFileError,
            courtMandateStatus: getCourtMandateFileStatus,
            birthCertificateError: getBirthCertificateFileError,
            birthCertificateStatus: getBirthCertificateFileStatus,
        })
    );

    useEffect(() => {
        if (includeAvs) {
            dispatch(toggleIncludeAvs());
        }

        if (includeCdv) {
            dispatch(toggleIncludeCdv());
        }
    }, []);

    //encode image data using btoa dispatch action add to store
    const encodeFile = (action, input) => {
        const file = input.files['0'];

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => {
            dispatch(
                action({
                    value: btoa(reader.result),
                    type: file.type,
                    size: file.size,
                })
            );
        };
    };

    return (
        <div className="tfsa-payment-details">
            <div className="tfsa-payment-details-wrapper">
                <h5 className="heading " id="heading-anchor">
                    Now, we'd like to know <strong>your payment details</strong>
                </h5>
                <section>
                    <h6>Banking details</h6>
                    <OmFormDropdownFieldWrapper
                        id="bankName"
                        useNativeMobileDropdown
                        required
                        size="medium"
                        formId="paymentDetailsForm"
                        placeholder="Bank name"
                        name="bankName"
                        align="left"
                        value={bankName.value}
                        state={bankName.status}
                        errorMessage={bankName.error}
                        options={
                            referenceBanks &&
                            JSON.stringify(
                                referenceBanks.map((option) => ({
                                    value: option.code,
                                    label: option.description,
                                }))
                            )
                        }
                        onOnSelect={(event) => dispatch(setBankNameValue(event.detail.id))}
                    />
                    <OmFormInputFieldWrapper
                        id="accountHolderName"
                        isSensitive
                        required
                        disabled
                        formId="paymentDetailsForm"
                        size="full"
                        align="left"
                        placeholder="Account holder name"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={name.value + ' ' + surname.value}
                        state={'disabled'}
                        errorMessage={null}
                    />
                    <OmFormInputFieldWrapper
                        id="accountNumber"
                        isSensitive
                        required
                        keyboardMode="numeric"
                        formId="paymentDetailsForm"
                        mask="number"
                        size="full"
                        align="left"
                        placeholder="Account holder number"
                        validationDelay={0}
                        value={accountNumber.value}
                        state={accountNumberInput?.status}
                        errorMessage={accountNumberInput?.error}
                        onPersist={(event) => {
                            if (event.detail !== accountNumber.value) {
                                dispatch(setAccountNumberValue(event.detail));
                            }
                        }}
                        onInputBlur={() => setAccountNumberInput(accountNumber)}
                        onFocus={() => setAccountNumberInput(null)}
                        hintText="E.g. 633456765"
                    />
                    <OmFormDropdownFieldWrapper
                        id="accountType"
                        useNativeMobileDropdown
                        required
                        size="medium"
                        formId="paymentDetailsForm"
                        placeholder="Account type"
                        name="accountType"
                        align="left"
                        value={accountType.value}
                        state={accountType.status}
                        errorMessage={accountType.error}
                        options={
                            referenceAccountTypes &&
                            JSON.stringify(
                                referenceAccountTypes.map((option) => ({
                                    value: option.code,
                                    label: option.description,
                                }))
                            )
                        }
                        onOnSelect={(event) => dispatch(setAccountTypeValue(event.detail.id))}
                    />
                </section>

                {(contributionType.value === CONTRIBUTION_TYPE.MONTHLY ||
                    contributionType.value === CONTRIBUTION_TYPE.COMBINATION) && (
                    <section>
                        <h6>Regular contribution</h6>
                        <OmFormDropdownFieldWrapper
                            id="debitOrderDay"
                            useNativeMobileDropdown
                            required
                            size="medium"
                            formId="paymentDetailsForm"
                            placeholder="Select a day for your debit order"
                            placeholderAsLabel="false"
                            label="Debit order day"
                            name="debitOrderDay"
                            align="left"
                            value={debitOrderDay.value}
                            state={debitOrderDay.status}
                            errorMessage={debitOrderDay.error}
                            options={
                                referenceDebitOrderDays &&
                                JSON.stringify(
                                    referenceDebitOrderDays.map((option) => ({
                                        value: option.description,
                                        label: option.description,
                                    }))
                                )
                            }
                            onOnSelect={(event) => dispatch(setDebitOrderDayValue(event.detail.id))}
                        />
                        <OmFormDropdownFieldWrapper
                            id="month"
                            disabled={!debitOrderDay.value}
                            key={debitOrderDay.value}
                            useNativeMobileDropdown
                            required
                            size="medium"
                            formId="paymentDetailsForm"
                            placeholder="Select a starting month"
                            placeholderAsLabel="false"
                            label="Starting month"
                            name="month"
                            align="left"
                            value={month.value}
                            state={month.status}
                            errorMessage={month.error}
                            options={
                                referenceDebitOrderMonths &&
                                JSON.stringify(
                                    referenceDebitOrderMonths.map((option) => ({
                                        value: option,
                                        label: option,
                                    }))
                                )
                            }
                            onOnSelect={(event) => dispatch(setMonthValue(event.detail.id))}
                        />

                        <div className={debitOrderDay.value && month.value ? '' : 'hide'}>
                            <OmApplicationTooltip borderLeft="false" small>
                                <div className="icon-rectangle" slot="icon">
                                    <OmIcon icon-name="calendar"></OmIcon>
                                </div>
                                <p slot="tooltip-content">
                                    You have chosen to increase your contribution {blurbText}&nbsp; yearly. Your
                                    contribution will increase on {debitOrderDay.value}&nbsp;
                                    {month.value ? month.value.split(' ')[0] : ''}&nbsp;
                                    {new Date().getFullYear() + 1}
                                </p>
                            </OmApplicationTooltip>
                        </div>

                        {/* Skip a month section not to be included for now, once SAT is live then we can reintroduce the skip a month functionality */}
                        {/* Speak to Wes Nel for more information */}
                        {false && (
                            <>
                                <h5>Would you like to skip a month?</h5>
                                <h6 className="skip-month-text">
                                    Skip a month allows you to take a single payment break at any month of your
                                    choice.&nbsp;
                                </h6>
                                <OmRadioButtonGroup
                                    vertical
                                    value={skipMonth}
                                    onOnSelection={(event) => dispatch(toggleSkipMonth(event))}
                                >
                                    <ul slot="list-items">
                                        <li value="true">Yes</li>
                                        <li value="false">No</li>
                                    </ul>
                                </OmRadioButtonGroup>
                                {skipMonth && (
                                    <OmFormDropdownFieldWrapper
                                        id="monthToSkip"
                                        useNativeMobileDropdown
                                        required
                                        size="medium"
                                        formId="paymentDetailsForm"
                                        placeholder="Select a month"
                                        placeholderAsLabel="false"
                                        label="Month"
                                        name="monthToSkip"
                                        align="left"
                                        value={monthToSkip.value}
                                        state={monthToSkip.status}
                                        errorMessage={monthToSkip.error}
                                        options={
                                            calendarMonths &&
                                            JSON.stringify(
                                                calendarMonths.map((option) => ({
                                                    value: option,
                                                    label: option,
                                                }))
                                            )
                                        }
                                        onOnSelect={(event) => dispatch(setMonthToSkipValue(event.detail.id))}
                                    />
                                )}
                            </>
                        )}
                    </section>
                )}

                {(contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM ||
                    contributionType.value === CONTRIBUTION_TYPE.COMBINATION) && (
                    <section>
                        <h6>Lump sum contribution</h6>
                        <OmFormDropdownFieldWrapper
                            id="lumpSumDay"
                            useNativeMobileDropdown
                            required
                            size="medium"
                            formId="paymentDetailsForm"
                            placeholder="Select a day for your debit order"
                            placeholderAsLabel="false"
                            label="Debit order day"
                            name="lumpSumDay"
                            align="left"
                            value={lumpSumDay.value}
                            state={lumpSumDay.status}
                            errorMessage={lumpSumDay.error}
                            options={
                                referenceDebitOrderDays &&
                                JSON.stringify(
                                    referenceDebitOrderDays.map((option) => ({
                                        value: option.description,
                                        label: option.description,
                                    }))
                                )
                            }
                            onOnSelect={(event) => dispatch(setLumpSumDayValue(event.detail.id))}
                        />
                        <OmFormDropdownFieldWrapper
                            id="lumpSumMonth"
                            disabled={!lumpSumDay.value}
                            key={referenceLumpSumMonths}
                            useNativeMobileDropdown
                            required
                            size="medium"
                            formId="paymentDetailsForm"
                            placeholderAsLabel="false"
                            placeholder="Select a month"
                            label="Debit order month"
                            name="lumpSumMonth"
                            align="left"
                            value={lumpSumMonth.value}
                            state={lumpSumMonth.status}
                            errorMessage={lumpSumMonth.error}
                            options={
                                referenceLumpSumMonths &&
                                JSON.stringify(
                                    referenceLumpSumMonths.map((option) => ({
                                        value: option,
                                        label: option,
                                    }))
                                )
                            }
                            onOnSelect={(event) => dispatch(setLumpSumMonthValue(event.detail.id))}
                        />
                    </section>
                )}

                <div className="check-box-container " id="paymentAuthorization">
                    <OmCheckBox
                        disabled={!paymentDetailsCompleted}
                        onOnCheckBoxChanged={() => dispatch(togglePaymentAuthorization())}
                        text="I hereby authorise Old Mutual to withdraw all contributions that I undertake to pay towards my Tax Free Savings Plan from this bank account. I also confirm (1) that the account details are correct and (2) that I am authorised to give this instruction."
                        checked={paymentAuthorizationChecked}
                    />
                    <OmFormError message={paymentAuthorizationError}></OmFormError>
                </div>

                {checkboxChecked && (
                    <div id="legal-section" className="legal-section">
                        <h5>Your source of income </h5>
                        <h6 className="sessioncamhidetext">
                            {name.value}, to protect you and fellow South Africans from fraud and money laundering, we
                            need to know about your earnings.
                        </h6>
                        <section>
                            <OmFormDropdownFieldWrapper
                                id="sourceOfIncome"
                                useNativeMobileDropdown
                                required
                                size="medium"
                                formId="paymentDetailsForm"
                                placeholder="Source of income"
                                name="sourceOfIncome"
                                align="left"
                                value={sourceOfIncome.value}
                                state={sourceOfIncome.status}
                                errorMessage={sourceOfIncome.error}
                                options={
                                    referenceSourceOfIncome &&
                                    JSON.stringify(
                                        referenceSourceOfIncome.map((option) => ({
                                            value: option.code,
                                            label: option.description,
                                        }))
                                    )
                                }
                                onOnSelect={(event) => dispatch(setSourceOfIncomeValue(event.detail.id))}
                            />
                            {isSourceOfIncomeOtherRequired && (
                                <OmFormInputFieldWrapper
                                    id="sourceOfIncomeOther"
                                    name="sourceOfIncomeOther"
                                    isSensitive
                                    required
                                    formId="paymentDetailsForm"
                                    placeholderAsLabel="false"
                                    label="Source of income (please specify)"
                                    size="full"
                                    align="left"
                                    placeholder="Please specify"
                                    validationDelay={0}
                                    value={sourceOfIncomeOther.value}
                                    state={sourceOfIncomeOtherInput?.status}
                                    errorMessage={sourceOfIncomeOtherInput?.error}
                                    onPersist={(event) => dispatch(setSourceOfIncomeOtherValue(event.detail))}
                                    onInputBlur={() => setSourceOfIncomeOtherInput(sourceOfIncomeOther)}
                                    onFocus={() => setSourceOfIncomeOtherInput(null)}
                                />
                            )}
                            <OmFormDropdownFieldWrapper
                                id="sourceOfFunds"
                                useNativeMobileDropdown
                                required
                                size="medium"
                                formId="paymentDetailsForm"
                                placeholder="Source of funds"
                                name="sourceOfFunds"
                                align="left"
                                value={sourceOfFunds.value}
                                state={sourceOfFunds.status}
                                errorMessage={sourceOfFunds.error}
                                options={
                                    referenceSourceOfFunds &&
                                    JSON.stringify(
                                        referenceSourceOfFunds.map((option) => ({
                                            value: option.code,
                                            label: option.description,
                                        }))
                                    )
                                }
                                onOnSelect={(event) => dispatch(setSourceOfFundsValue(event.detail.id))}
                            />
                            <OmFormDropdownFieldWrapper
                                id="employmentPosition"
                                useNativeMobileDropdown
                                required
                                size="medium"
                                formId="paymentDetailsForm"
                                placeholderAsLabel="false"
                                label="Employment position"
                                placeholder="What do you do for a living?"
                                name="employmentPosition"
                                align="left"
                                value={employmentPosition.value}
                                state={employmentPosition.status}
                                errorMessage={employmentPosition.error}
                                options={
                                    referenceEmploymentPosition &&
                                    JSON.stringify(
                                        referenceEmploymentPosition.map((option) => ({
                                            value: option.code,
                                            label: option.description,
                                        }))
                                    )
                                }
                                onOnSelect={(event) => dispatch(setEmploymentPositionValue(event.detail.id))}
                            />
                            {isEmploymentIndustryRequired && (
                                <OmFormDropdownFieldWrapper
                                    id="employmentIndustry"
                                    useNativeMobileDropdown
                                    required
                                    size="medium"
                                    formId="paymentDetailsForm"
                                    placeholderAsLabel="false"
                                    label="Employment industry"
                                    placeholder="What industry do you work in?"
                                    name="employmentIndustry"
                                    align="left"
                                    value={employmentIndustry.value}
                                    state={employmentIndustry.status}
                                    errorMessage={employmentIndustry.error}
                                    options={
                                        referenceEmploymentIndustry &&
                                        JSON.stringify(
                                            referenceEmploymentIndustry.map((option) => ({
                                                value: option.code,
                                                label: option.description,
                                            }))
                                        )
                                    }
                                    onOnSelect={(event) => dispatch(setEmploymentIndustryValue(event.detail.id))}
                                />
                            )}
                            {isEmploymentIndustryRequired && isEmploymentIndustryOtherRequired && (
                                <OmFormInputFieldWrapper
                                    id="employmentIndustryOther"
                                    name="employmentIndustryOther"
                                    isSensitive
                                    required
                                    formId="paymentDetailsForm"
                                    placeholderAsLabel="false"
                                    label="Employment industry (please specify)"
                                    size="full"
                                    align="left"
                                    placeholder="Please specify"
                                    validationDelay={0}
                                    value={employmentIndustryOther.value}
                                    state={employmentIndustryOtherInput?.status}
                                    errorMessage={employmentIndustryOtherInput?.error}
                                    onPersist={(event) => dispatch(setEmploymentIndustryOtherValue(event.detail))}
                                    onInputBlur={() => setEmploymentIndustryOtherInput(employmentIndustryOther)}
                                    onFocus={() => setEmploymentIndustryOtherInput(null)}
                                />
                            )}
                        </section>
                    </div>
                )}
                {savingForMinor && checkboxChecked && (
                    <div className="document-upload">
                        <h6 className="medium upload-heading no-bottom-margin">
                            <strong>Upload documents</strong>
                        </h6>
                        {isParent && !isMinorAndParentSurnameSame ? (
                            <h6>
                                Since the child's surname differs from yours, we need a copy of an unabridged birth
                                certificate to confirm you are the child's parents.
                            </h6>
                        ) : (
                            <h6>
                                You can upload either the child's abridged/unabridged birth certificate, South African
                                ID, or passport.
                            </h6>
                        )}

                        <h6 className="medium file-format-label">File format: JPG - PDF - PNG</h6>
                        {
                            // must be parent and have the same surname as minor
                            isParent && isMinorAndParentSurnameSame && (
                                <OmApplicationUploadCard
                                    heading="Child's proof of identity"
                                    onOnFileSelected={(event) =>
                                        encodeFile(setIdentityDocumentValue, event.detail.currentTarget)
                                    }
                                    onOnFileDropped={(event) =>
                                        encodeFile(setIdentityDocumentValue, event.detail.dataTransfer)
                                    }
                                    onOnFileDelete={() => dispatch(removeIdentityDocumentValue())}
                                    status={identityDocumentStatus}
                                >
                                    <OmPopUpMessage
                                        slot="error-message"
                                        className="error-pop-up"
                                        message={identityDocumentError}
                                        align="center "
                                        mobileAlign="center"
                                        messageWidth="160"
                                        mobileMarginLeftOffset="-80"
                                        marginLeftOffset="-80"
                                        displayArrow={false}
                                    />
                                </OmApplicationUploadCard>
                            )
                        }
                        {
                            // must be parent and not have the same surname as minor
                            isParent && !isMinorAndParentSurnameSame && (
                                <OmApplicationUploadCard
                                    heading="Unabridged birth certificate"
                                    onOnFileSelected={(event) =>
                                        encodeFile(setBirthCertificateValue, event.detail.currentTarget)
                                    }
                                    onOnFileDropped={(event) =>
                                        encodeFile(setBirthCertificateValue, event.detail.dataTransfer)
                                    }
                                    onOnFileDelete={() => dispatch(removeBirthCertificateValue())}
                                    status={birthCertificateStatus}
                                >
                                    <OmPopUpMessage
                                        slot="error-message"
                                        className="error-pop-up"
                                        message={birthCertificateError}
                                        align="center "
                                        mobileAlign="center"
                                        messageWidth="160"
                                        mobileMarginLeftOffset="-80"
                                        marginLeftOffset="-80"
                                        displayArrow={false}
                                    />
                                </OmApplicationUploadCard>
                            )
                        }
                        {
                            // must be legalGuarduan
                            isLegalGuardian && (
                                <>
                                    <OmApplicationUploadCard
                                        heading="Child's proof of identity"
                                        onOnFileSelected={(event) =>
                                            encodeFile(setIdentityDocumentValue, event.detail.currentTarget)
                                        }
                                        onOnFileDropped={(event) =>
                                            encodeFile(setIdentityDocumentValue, event.detail.dataTransfer)
                                        }
                                        onOnFileDelete={() => dispatch(removeIdentityDocumentValue())}
                                        status={identityDocumentStatus}
                                    >
                                        <OmPopUpMessage
                                            slot="error-message"
                                            className="error-pop-up"
                                            message={identityDocumentError}
                                            align="center"
                                            mobileAlign="center"
                                            messageWidth="160"
                                            displayArrow={false}
                                        />
                                    </OmApplicationUploadCard>
                                    <OmApplicationUploadCard
                                        heading="Court mandate"
                                        onOnFileSelected={(event) =>
                                            encodeFile(setCourtMandateValue, event.detail.currentTarget)
                                        }
                                        onOnFileDropped={(event) =>
                                            encodeFile(setCourtMandateValue, event.detail.dataTransfer)
                                        }
                                        onOnFileDelete={() => dispatch(removeCourtMandateValue())}
                                        status={courtMandateStatus}
                                    >
                                        <OmPopUpMessage
                                            slot="error-message"
                                            className="error-pop-up"
                                            message={courtMandateError}
                                            align="center"
                                            mobileAlign="center"
                                            messageWidth="160"
                                            displayArrow={false}
                                        />
                                    </OmApplicationUploadCard>
                                </>
                            )
                        }
                    </div>
                )}
                <OmModalLoader
                    loaderText="Verifying your bank account details"
                    verticalAlignMiddle="true"
                    open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.PENDING}
                />

                <ErrorResponseModal
                    open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.FAILED}
                    buttonHandler={() => dispatch(setAccountValidationReset())}
                />

                <SuccessModal
                    open={displaySuccessModal}
                    buttonHandler={() => dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.IDLE))}
                />

                <ServerErrorResponseModal
                    open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.ERROR}
                    // set status to idle and let user proceed when server error
                    buttonHandler={() => dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.IDLE))}
                />
            </div>
        </div>
    );
};

export default PaymentDetailsPage;

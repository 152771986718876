import { OmFormDropdownFieldWrapper, OmFormInputFieldWrapper } from '@om/component-library-react';
import { setIdValue, setNationalityValue } from '@om/redux/actions/taxFreeSavingsAccount/auxiliaryDetails';
import { getId, getNationality } from '@om/redux/selectors/taxFreeSavingsAccount/auxiliaryDetails';
import { getReferenceNationalitiesList } from '@om/redux/selectors/taxFreeSavingsAccount/referenceData';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MarriageDetailsSection from './MarriageDetailsSection';

const PersonalDetailsSection = () => {
    const [idInput, setIdInput] = useState();

    const dispatch = useDispatch();
    const { idNumber, nationality, referenceNationalities } = useSelector(
        createStructuredSelector({
            idNumber: getId,
            nationality: getNationality,
            referenceNationalities: getReferenceNationalitiesList,
        })
    );

    return (
        <section>
            <h6 className="medium no-bottom-margin">Your personal details</h6>

            <OmFormDropdownFieldWrapper
                id="nationality"
                useNativeMobileDropdown
                required
                size="medium"
                formId="personalDetailsForm"
                placeholder="Nationality"
                name="title"
                align="left"
                value={nationality?.value}
                state={nationality?.status}
                errorMessage={nationality?.error}
                options={
                    referenceNationalities &&
                    JSON.stringify(
                        referenceNationalities.map((option) => ({ value: option.code, label: option.description }))
                    )
                }
                onOnSelect={(event) => dispatch(setNationalityValue(event.detail.id))}
            />

            <OmFormInputFieldWrapper
                id="id"
                isSensitive
                required
                keyboardMode="numeric"
                formId="personalDetailsForm"
                size="full"
                align="left"
                placeholder="South African ID number"
                validationDelay={0}
                value={idNumber?.value}
                state={idInput?.status}
                errorMessage={idInput?.error}
                onPersist={(event) => dispatch(setIdValue(event.detail))}
                onInputBlur={() => setIdInput(idNumber)}
                onFocus={() => setIdInput(null)}
                hintText="E.g. 790210111111"
            />

            <MarriageDetailsSection />
        </section>
    );
};

export default PersonalDetailsSection;

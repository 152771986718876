import React from 'react';
import {
    OmApplicationPopupModal,
    OmButton
} from '@om/component-library-react';

const LimitExceededModal = ({ open, buttonHandler }) => {
    return (
        <OmApplicationPopupModal
            name="LimitModal"
            alignment="left"
            dismissible
            onModalClosed={() => buttonHandler()}
            open={open}>
            <div slot="content">
                <strong>Limit exceeded</strong>
                <br/>
                <p>You can only invest R36 000 per tax year. Please adjust your contribution amounts.</p>
            </div>
            <div slot="button">
                <OmButton
                    type="primary"
                    size="small"
                    text="OKAY"
                    onClick={() => buttonHandler()}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default LimitExceededModal;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    navigateNext,
    navigatePrevious,
    setNavigationDone,
} from '@om/redux/actions/taxFreeSavingsAccount/routing';
import {
    getActivePageIndex,
    getDoTfsaNavigation,
    getDisableContinueButton,
    getIsFormValid
} from '@om/redux/selectors/taxFreeSavingsAccount/routing';
import { getIsReferenceDataValid } from '@om/redux/selectors/taxFreeSavingsAccount/referenceData';
import { getIsFundDataValid } from '@om/redux/selectors/taxFreeSavingsAccount/fundData';
import { setErrorModalValue } from '@om/redux/actions/taxFreeSavingsAccount/referenceData';
import { PAGES, PAGE_INDICES, TFSA_PAGE_URLS } from '@om/redux/actions/taxFreeSavingsAccount/types';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import OmButton from '../../components/forms/OmButton';
import './ApplicationWizardFooter.scss'
import { OmApplicationWizardFooter  } from '@om/component-library-react';

const ApplicationWizardFooter = () => {
    const dispatch = useDispatch();
    const {
        activePageIndex,
        doTfsaNavigation,
        disableContinueButton,
        referenceDataValid,
        fundDataValid,
        isFormValid
    } = useSelector(
        createStructuredSelector({
            activePageIndex: getActivePageIndex,
            doTfsaNavigation: getDoTfsaNavigation,
            disableContinueButton: getDisableContinueButton,
            referenceDataValid: getIsReferenceDataValid,
            fundDataValid: getIsFundDataValid,
            isFormValid: getIsFormValid
        })
    );

    const location = useLocation();
    useEffect(() => {
        if (!doTfsaNavigation) return;
        if (activePageIndex < 0) return; 
        
        const currentPageIndex = PAGES.map((page) => page.URL).indexOf(location.pathname);
        if (currentPageIndex === activePageIndex) {
            dispatch(setNavigationDone());
            return;
        }

        navigate(PAGES[activePageIndex].URL, {replace: true});
    }, [ location, activePageIndex, dispatch, doTfsaNavigation ]);

    const navigateContinue = () => {
        if (activePageIndex === PAGE_INDICES.INVESTMENT_SETUP_CONTRIBUTION) {
            if (referenceDataValid && fundDataValid) {
                dispatch(navigateNext());
                return;
            }

            dispatch(setErrorModalValue(true));
            return;
        }

        dispatch(navigateNext());
    };

    const continueText = location.pathname.includes(TFSA_PAGE_URLS.REVIEW) ? 'SUBMIT' : 'CONTINUE';
    return (
        !location.pathname.includes(TFSA_PAGE_URLS.THANK_YOU) && (
            <OmApplicationWizardFooter index='activePageIndex' totalCount='totalSteps'>
                <OmButton
                    slot='left'
                    onClick={() => dispatch(navigatePrevious())}
                    type='text'
                    size='small'
                    text='Previous'
                />
                {(disableContinueButton || !isFormValid) ? (
                    <OmButton
                        className={'continue-button-disabled'}
                        slot='right'
                        onClick={() => navigateContinue()}
                        type='primary'
                        size='small'
                        text={continueText}
                    />
                ) : (
                    <OmButton
                        slot='right'
                        onClick={() => navigateContinue()}
                        type='primary'
                        size='small'
                        text={continueText}
                    />
                )}
            </OmApplicationWizardFooter>
        )
    );
};

export default ApplicationWizardFooter;

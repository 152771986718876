import React from 'react';
import { OmModalError } from '@om/component-library-react';
import OmButton from '../../../components/forms/OmButton';

const ReferenceDataErrorModal = ({ open, callMeBack }) => {
    return (
        <OmModalError
            errorHeaderText="Oops! Something went wrong."
            errorText="It's not you. It's us. Can we call you back to help you complete your application?"
            open={open}
        >
            <div slot="go-home">
                <OmButton
                    type="primary"
                    size="large"
                    text="Call me back"
                    actionType="Call Me Back"
                    callMeBackObject={callMeBack ? JSON.stringify(callMeBack) : ''}
                />
            </div>
        </OmModalError>
    );
};

export default ReferenceDataErrorModal;
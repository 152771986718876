import React from 'react';
import FundCheckboxAccordionItem from './FundCheckboxAccordionItem';

const FundCheckboxList = ({ fundList, selectedFunds }) => {
    return fundList.map((fund, index) => (
        <FundCheckboxAccordionItem
            key={fund.fundCode}
            isInLeftColumn={index % 3 === 0}
            checkboxId={fund.fundCode}
            title={fund.fundName}
            fees={fund.fees}
            subtitle={`${fund.riskProfile} risk`}
            buttonUrl={fund.factSheetUrl}
            selected={selectedFunds[fund.fundCode]}
            investmentInfo={fund.investmentInfo}
        />
    ));
};

export default FundCheckboxList;

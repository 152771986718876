import {
    OmApplicationPopupModal,
    OmIcon
} from '@om/component-library-react';
import React from 'react';
import OmButton from '../../../../components/forms/OmButton';

const ErrorResponseModal = ({ open, buttonHandler }) => {
    return (
        <OmApplicationPopupModal
            name="ErrorResponseModal"
            exitable={false}
            open={open}>
            <div slot="content">
                <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt94f2cb596218bd62/5f2935c8ee8504335e705005/WRONG_CMOS.svg" />
                <h5 className="no-bottom-margin">Something looks wrong?</h5>
                <h6 className="no-bottom-margin account-verification-text">Please re-enter your banking details. It appears that the details you have entered are incorrect or the account does not belong to you.</h6>
            </div>
            <div slot="button">
                <OmButton
                    type='primary'
                    size='large'
                    text='Try again'
                    onClick={() => buttonHandler()}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default ErrorResponseModal;
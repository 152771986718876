import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SideDrawer from '../../../components/SideDrawer';
import { setSideDrawersClosed } from '@om/redux/actions/taxFreeSavingsAccount/review';
import { getTermsAndConditionsSideDrawerOpen } from '@om/redux/selectors/taxFreeSavingsAccount/review';

const TermsAndCondtionsDrawer = () => {
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector(
        createStructuredSelector({
            drawerOpen: getTermsAndConditionsSideDrawerOpen,
        })
    );

    return (
        <SideDrawer isOpen={drawerOpen} onClose={() => dispatch(setSideDrawersClosed())}>
            <p>
                <strong> Terms and Conditions</strong>
            </p>
            <p>
                <strong>IMPORTANT NOTES FOR OLD MUTUAL INVEST TAX FREE PLAN</strong>
            </p>
            <p>
                <strong>I warrant (promise)</strong> that I have read and understood:
            </p>
            <ul>
                <li>The <strong>PRODUCT INFORMATION</strong> given to me during this transaction about the Tax Free Plan, and</li>
                <li>The <strong>FUND FACT SHEETS</strong> of the underlying investment funds that I selected.</li>
            </ul>
            <p>
                <strong>I understand and agree that:</strong>
            </p>
            <ol>
                <li>The Tax Free Plan is an insurance contract issued and underwritten by Old Mutual Life Assurance Company (South Africa) Limited, registration number 1996/004643/06 (Old Mutual), a licensed Financial Services Provider.</li>
                <li>All the information I have provided to Old Mutual in connection with the proposed Plan is true and correct. The information will form the basis of the proposed Plan.</li>
                <li>No statement that I (or anyone else) make is binding on Old Mutual, unless it is in writing and is accepted by Old Mutual and made part of the Plan.</li>
                <li>Old Mutual is entitled to delay the payment of any disinvestment or the processing of switches if any party holding assets on Old Mutual’s behalf or any of their partners delay the payment of the proceeds to Old Mutual. If a restriction is placed on the sale of assets in the underlying investment fund, which can happen from time to time, the same restriction will apply to my underlying investment fund. This can significantly delay the processing of disinvestments or switches.</li>
                <li>I am required to disclose all important facts. If I do not do this, the Plan may be invalid and I will only be paid back the amounts that I have paid, minus fees or any currency and investment losses.</li>
                <li>Old Mutual will not have any obligations until they notify me that they have accepted my application unconditionally and I have paid the first investment amount.</li>
                <li>Where the payment date that I specified is missed for whatever reason (e.g. delay on my bank’s side or in Old Mutual’s servicing department), the collection will happen at the soonest possible date thereafter.</li>
                <li>I can access my savings at any time by making a disinvestment. </li>
                <li>All contributions that I made into my plan, from the start, will be taken into account (added) when calculating whether I have reached the yearly or lifetime limits set by the Government. For purposes of this calculation, disinvestments will not be deducted from the total that I contributed.</li>
            </ol>
            <p>
                <strong>I declare that:</strong>
            </p>
            <ol>                
                <li>I will not receive interest or any returns on funds that I deposited in any account of Old Mutual (in whichever way) before Old Mutual has accepted this application. Old Mutual will invest my first investment amount after it has received it and has accepted this application. This will take place at the first investment opportunity and at the price applicable at that time.</li>
                <li>The value of the Plan is based on the disinvestment value of all investment funds. The effective date and disinvestment value of each investment option depends on the terms and conditions of the investment funds I have selected.</li>
                <li>Old Mutual is entitled to carry out an instruction to withdraw an investment over a period of up to seven business days and to determine the value according to the amounts realised for the investment over that period.</li>
                <li><strong>Our AML obligations</strong><br/>
                We have to apply South Africa’s laws against money laundering (AML) and terrorist financing. We have to perform a customer due diligence exercise on any person associated with this plan (including you) before making payment to them. This will include any person that you nominate as a beneficiary or replacement owner, where applicable. It also applies to any owner to whom you cede the plan, where allowed.  
                </li>
            </ol>

            <p><strong>I confirm that:</strong></p>
            <ol>
                <li>I have read and understood the description of the products and the charges and fees payable, as explained to me during this transaction.</li>
                <li>Old Mutual may change the charges from time to time. The actual charges on my Plan will be those that apply on the date that these charges are deducted.</li>
                <li>My selected investment funds do not provide any guarantee, unless such a guarantee is specifically shown in the fund fact sheet.</li>
            </ol>

            <p><strong>PROTECTION OF PERSONAL INFORMATION ACT (POPIA) NOTICE</strong></p>

            <p>The Old Mutual Group would like to offer you ongoing financial services and may use your personal information to provide you with information about products or services that may be suitable to meet your financial needs. Please sms your ID number to 30994 if you would prefer not to receive such information and/or financial services
            We may use your information or obtain information about you for the following purposes:</p>
            <ul>
                <li>Underwriting</li>
                <li>Assessment and processing of claims</li>
                <li>Credit searches and/or verification of personal information</li>
                <li>Claims checks (ASISA Life & Claims Register)</li>
                <li>Tracing beneficiaries</li>
                <li>Fraud prevention and detection</li>
                <li>Market research and statistical analysis</li>
                <li>Audit & record keeping purposes</li>
                <li>Compliance with legal & regulatory requirements</li>
                <li>Verifying your identity</li>
                <li>Sharing information with service providers we engage to process such information on our behalf or who render services to us. These service providers may be abroad, but we will not share your information with them unless we are satisfied that they have adequate security measures in place to protect your personal information.
                    You may access your personal information that we hold and may also request us to correct any errors or to delete this information. In certain cases you have the right to object to the processing of your personal information.</li>
            </ul>
            <p>
                You also have the right to complain to the Information Regulator, whose contact details are:
            </p>
            <p>        
                http://www.justice.gov.za/inforeg/index.html<br/>
                Tel: 012 406 4818<br/>
                Fax: 086 500 3351<br/>
                Email: inforeg@justice.gov.za<br/>
            </p>
            <p>  
                To view our full privacy notice and to exercise your preferences, please visit our website on www.oldmutual.co.za
            </p>
            <p><strong>MY RIGHT TO CANCEL THIS INVESTMENT</strong></p>
            <p>Old Mutual will send me an e-mail with the full Plan details as soon as it is issued. I will also be able to view my Plan when I log in to the MyPortfolio page of Old Mutual's website. I will read all the particulars carefully. If I am not 100% happy with the Plan, I can cancel it without penalty within 31 days of receiving the email.
            The contact details of Old Mutual's complaints department appears in the details of my Plan, if you have a complaint about my product.
            </p>
        </SideDrawer>
    );
};

export default TermsAndCondtionsDrawer;

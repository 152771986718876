import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { OmFormDropdownFieldWrapper, OmFormInputFieldWrapper, OmRadioButtonGroup } from '@om/component-library-react';

import {
    setCityValue,
    setComplexValue,
    setCountryValue,
    setPoBoxValue,
    setPoCountryValue,
    setPoPostOfficeValue,
    setPoPostalCodeValue,
    setPostalAddressMethodValue,
    setPostalCityValue,
    setPostalCodeValue,
    setPostalComplexValue,
    setPostalCountryValue,
    setPostalPostalCodeValue,
    setPostalStreetAddressValue,
    setPostalSuburbValue,
    setPrivateBagValue,
    setPrivateCountryValue,
    setPrivatePostOfficeValue,
    setPrivatePostalCodeValue,
    setStreetAddressValue,
    setSuburbValue,
    togglePostalAddressSameValue,
} from '@om/redux/actions/taxFreeSavingsAccount/auxiliaryDetails';
import {
    getCity,
    getComplex,
    getCountry,
    getIsPostalAddressSame,
    getIsPostalMethodNotSet,
    getIsPostalMethodPoBox,
    getIsPostalMethodPrivateBag,
    getIsPostalMethodStreet,
    getPoBox,
    getPoCountry,
    getPoPostOffice,
    getPoPostalCode,
    getPostalAddressMethod,
    getPostalCity,
    getPostalCode,
    getPostalComplex,
    getPostalCountry,
    getPostalPostalCode,
    getPostalStreetAddress,
    getPostalSuburb,
    getPrivateBag,
    getPrivateCountry,
    getPrivatePostOffice,
    getPrivatePostalCode,
    getStreetAddress,
    getSuburb,
} from '@om/redux/selectors/taxFreeSavingsAccount/auxiliaryDetails';
import {
    getReferenceNationalitiesList,
    getReferencePostalMethods,
} from '@om/redux/selectors/taxFreeSavingsAccount/referenceData';

const AddressDetailsSection = () => {
    const [streetAddressInput, setStreetAddressInput] = useState();
    const [complexInput, setComplexInput] = useState();
    const [suburbInput, setSuburbInput] = useState();
    const [cityInput, setCityInput] = useState();
    const [postalCodeInput, setPostalCodeInput] = useState();
    const [postalComplexInput, setPostalComplexInput] = useState();
    const [postalStreetAddressInput, setPostalStreetAddressInput] = useState();
    const [postalSuburbInput, setPostalSuburbInput] = useState();
    const [postalCityInput, setPostalCityInput] = useState();
    const [postalPostalCodeInput, setPostalPostalCodeInput] = useState();
    const [poBoxNumberInput, setPoBoxNumberInput] = useState();
    const [poPostOfficeInput, setPoPostOfficeInput] = useState();
    const [poPostalCodeInput, setPoPostalCodeInput] = useState();
    const [privateBagInput, setPrivateBagInput] = useState();
    const [privatePostOfficeInput, setPrivatePostOfficeInput] = useState();
    const [privatePostalCodeInput, setPrivatePostalCodeInput] = useState();

    const dispatch = useDispatch();
    const {
        complex,
        streetAddress,
        suburb,
        city,
        postalCode,
        country,

        referenceCountriesOfBirth,
        referencePostalMethods,

        isPostalAddressSame,
        postalAddressMethod,
        isPostalMethodStreet,
        isPostalMethodPoBox,
        isPostalMethodPrivateBag,
        isPostalMethodNotSet,

        postalComplex,
        postalStreetAddress,
        postalSuburb,
        postalCity,
        postalPostalCode,
        postalCountry,

        privateBag,
        privatePostOffice,
        privatePostalCode,
        privateCountry,

        poBox,
        poPostOffice,
        poPostalCode,
        poCountry,
    } = useSelector(
        createStructuredSelector({
            complex: getComplex,
            streetAddress: getStreetAddress,
            suburb: getSuburb,
            city: getCity,
            postalCode: getPostalCode,
            country: getCountry,

            referenceCountriesOfBirth: getReferenceNationalitiesList,
            referencePostalMethods: getReferencePostalMethods,

            isPostalAddressSame: getIsPostalAddressSame,
            postalAddressMethod: getPostalAddressMethod,
            isPostalMethodStreet: getIsPostalMethodStreet,
            isPostalMethodPoBox: getIsPostalMethodPoBox,
            isPostalMethodPrivateBag: getIsPostalMethodPrivateBag,
            isPostalMethodNotSet: getIsPostalMethodNotSet,

            postalComplex: getPostalComplex,
            postalStreetAddress: getPostalStreetAddress,
            postalSuburb: getPostalSuburb,
            postalCity: getPostalCity,
            postalPostalCode: getPostalPostalCode,
            postalCountry: getPostalCountry,

            privateBag: getPrivateBag,
            privatePostOffice: getPrivatePostOffice,
            privatePostalCode: getPrivatePostalCode,
            privateCountry: getPrivateCountry,

            poBox: getPoBox,
            poPostOffice: getPoPostOffice,
            poPostalCode: getPoPostalCode,
            poCountry: getPoCountry,
        })
    );

    return (
        <>
            <h6 className="medium no-bottom-margin">Your address details</h6>
            <OmFormInputFieldWrapper
                id="streetAddress"
                isSensitive
                required
                formId="personalDetailsForm"
                size="full"
                align="left"
                placeholder="Street number and name"
                validationDelay={0}
                value={streetAddress.value}
                state={streetAddressInput?.status}
                errorMessage={streetAddressInput?.error}
                onPersist={(event) => dispatch(setStreetAddressValue(event.detail))}
                onInputBlur={() => setStreetAddressInput(streetAddress)}
                onFocus={() => setStreetAddressInput(null)}
                hintText="E.g. 17 Blue street."
            />
            <OmFormInputFieldWrapper
                id="complex"
                isSensitive
                required
                formId="personalDetailsForm"
                size="full"
                align="left"
                placeholder="Complex/building name (optional)"
                validationDelay={0}
                value={complex.value}
                state={complexInput?.status}
                errorMessage={complexInput?.error}
                onPersist={(event) => dispatch(setComplexValue(event.detail))}
                onInputBlur={() => setComplexInput(complex)}
                onFocus={() => setComplexInput(null)}
                hintText="Complex or building name, unit number or floor"
            />
            <OmFormInputFieldWrapper
                id="suburb"
                isSensitive
                required
                formId="personalDetailsForm"
                size="full"
                align="left"
                placeholder="Suburb"
                validationDelay={0}
                value={suburb.value}
                state={suburbInput?.status}
                errorMessage={suburbInput?.error}
                onPersist={(event) => dispatch(setSuburbValue(event.detail))}
                onInputBlur={() => setSuburbInput(suburb)}
                onFocus={() => setSuburbInput(null)}
            />
            <OmFormInputFieldWrapper
                id="city"
                required
                formId="personalDetailsForm"
                size="full"
                align="left"
                placeholder="City"
                validationDelay={0}
                value={city.value}
                state={cityInput?.status}
                errorMessage={cityInput?.error}
                onPersist={(event) => dispatch(setCityValue(event.detail))}
                onInputBlur={() => setCityInput(city)}
                onFocus={() => setCityInput(null)}
            />
            <OmFormInputFieldWrapper
                id="postalCode"
                isSensitive
                required
                formId="personalDetailsForm"
                keyboardMode="numeric"
                size="full"
                align="left"
                placeholder="Postal code"
                validationDelay={0}
                value={postalCode.value}
                state={postalCodeInput?.status}
                errorMessage={postalCodeInput?.error}
                onPersist={(event) => dispatch(setPostalCodeValue(event.detail))}
                onInputBlur={() => setPostalCodeInput(postalCode)}
                onFocus={() => setPostalCodeInput(null)}
            />
            <OmFormDropdownFieldWrapper
                id="country"
                useNativeMobileDropdown
                required
                size="medium"
                formId="personalDetailsForm"
                placeholder="Country"
                name="title"
                align="left"
                value={country.value}
                state={country.status}
                errorMessage={country.error}
                options={
                    referenceCountriesOfBirth &&
                    JSON.stringify(
                        referenceCountriesOfBirth.map((option) => ({
                            value: option.code,
                            label: option.description,
                        }))
                    )
                }
                onOnSelect={(event) => dispatch(setCountryValue(event.detail.id))}
            />
            <h6 className="medium radio-button-heading" id="postalAddressSame">
                Is your postal address the same?
            </h6>
            <OmRadioButtonGroup
                vertical
                value={isPostalAddressSame}
                groupName="postalAddress"
                onOnSelection={(event) => dispatch(togglePostalAddressSameValue(event.detail))}
            >
                <ul slot="list-items">
                    <li value="true">Yes</li>
                    <li value="false">No</li>
                </ul>
            </OmRadioButtonGroup>
            {!isPostalAddressSame && (
                <section
                    className={isPostalMethodNotSet ? 'preferred-postal-method last-field' : 'preferred-postal-method'}
                >
                    <h6 className="medium no-bottom-margin">Select your preferred postal method</h6>
                    <OmFormDropdownFieldWrapper
                        id="postalAddressMethod"
                        useNativeMobileDropdown
                        required
                        size="medium"
                        formId="personalDetailsForm"
                        align="left"
                        value={postalAddressMethod.value}
                        state={postalAddressMethod.status}
                        errorMessage={postalAddressMethod.error}
                        options={
                            referencePostalMethods &&
                            JSON.stringify(
                                referencePostalMethods.map((option) => ({
                                    value: option.code,
                                    label: option.description,
                                }))
                            )
                        }
                        onOnSelect={(event) => dispatch(setPostalAddressMethodValue(event.detail.id))}
                    />
                </section>
            )}
            {!isPostalAddressSame && isPostalMethodStreet && (
                <section>
                    <h6 className="medium no-bottom-margin">Please complete the following</h6>
                    <OmFormInputFieldWrapper
                        id="postalComplex"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="Complex/building"
                        validationDelay={0}
                        value={postalComplex.value}
                        state={postalComplexInput?.status}
                        errorMessage={postalComplexInput?.error}
                        onPersist={(event) => dispatch(setPostalComplexValue(event.detail))}
                        onInputBlur={() => setPostalComplexInput(postalComplex)}
                        onFocus={() => setPostalComplexInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="postalStreetAddress"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="Street address"
                        validationDelay={0}
                        value={postalStreetAddress.value}
                        state={postalStreetAddressInput?.status}
                        errorMessage={postalStreetAddressInput?.error}
                        onPersist={(event) => dispatch(setPostalStreetAddressValue(event.detail))}
                        onInputBlur={() => setPostalStreetAddressInput(postalStreetAddress)}
                        onFocus={() => setPostalStreetAddressInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="postalSuburb"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="Suburb"
                        validationDelay={0}
                        value={postalSuburb.value}
                        state={postalSuburbInput?.status}
                        errorMessage={postalSuburbInput?.error}
                        onPersist={(event) => dispatch(setPostalSuburbValue(event.detail))}
                        onInputBlur={() => setPostalSuburbInput(postalSuburb)}
                        onFocus={() => setPostalSuburbInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="postalCity"
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="City"
                        validationDelay={0}
                        value={postalCity.value}
                        state={postalCityInput?.status}
                        errorMessage={postalCityInput?.error}
                        onPersist={(event) => dispatch(setPostalCityValue(event.detail))}
                        onInputBlur={() => setPostalCityInput(postalCity)}
                        onFocus={() => setPostalCityInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="postalPostalCode"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        keyboardMode="numeric"
                        size="full"
                        align="left"
                        placeholder="Postal code"
                        validationDelay={0}
                        value={postalPostalCode.value}
                        state={postalPostalCodeInput?.status}
                        errorMessage={postalPostalCodeInput?.error}
                        onPersist={(event) => dispatch(setPostalPostalCodeValue(event.detail))}
                        onInputBlur={() => setPostalPostalCodeInput(postalPostalCode)}
                        onFocus={() => setPostalPostalCodeInput(null)}
                    />
                    <OmFormDropdownFieldWrapper
                        id="postalCountry"
                        useNativeMobileDropdown
                        required
                        size="medium"
                        formId="personalDetailsForm"
                        placeholder="Country"
                        name="title"
                        align="left"
                        value={postalCountry.value}
                        state={postalCountry.status}
                        errorMessage={postalCountry.error}
                        options={
                            referenceCountriesOfBirth &&
                            JSON.stringify(
                                referenceCountriesOfBirth.map((option) => ({
                                    value: option.code,
                                    label: option.description,
                                }))
                            )
                        }
                        onOnSelect={(event) => dispatch(setPostalCountryValue(event.detail.id))}
                    />
                </section>
            )}

            {!isPostalAddressSame && isPostalMethodPoBox && (
                <section>
                    <h6 className="medium no-bottom-margin">Please complete the following</h6>
                    <OmFormInputFieldWrapper
                        id="poBoxNumber"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        keyboardMode="numeric"
                        size="full"
                        align="left"
                        placeholder="PO box number"
                        validationDelay={0}
                        value={poBox.value}
                        state={poBoxNumberInput?.status}
                        errorMessage={poBoxNumberInput?.error}
                        onPersist={(event) => dispatch(setPoBoxValue(event.detail))}
                        onInputBlur={() => setPoBoxNumberInput(poBox)}
                        onFocus={() => setPoBoxNumberInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="poPostOffice"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="Post office"
                        validationDelay={0}
                        value={poPostOffice.value}
                        state={poPostOfficeInput?.status}
                        errorMessage={poPostOfficeInput?.error}
                        onPersist={(event) => dispatch(setPoPostOfficeValue(event.detail))}
                        onInputBlur={() => setPoPostOfficeInput(poPostOffice)}
                        onFocus={() => setPoPostOfficeInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="poPostalCode"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        keyboardMode="numeric"
                        size="full"
                        align="left"
                        placeholder="Postal code"
                        validationDelay={0}
                        value={poPostalCode.value}
                        state={poPostalCodeInput?.status}
                        errorMessage={poPostalCodeInput?.error}
                        onPersist={(event) => dispatch(setPoPostalCodeValue(event.detail))}
                        onInputBlur={() => setPoPostalCodeInput(poPostalCode)}
                        onFocus={() => setPoPostalCodeInput(null)}
                    />
                    <OmFormDropdownFieldWrapper
                        id="poPostalCountry"
                        useNativeMobileDropdown
                        required
                        size="medium"
                        formId="personalDetailsForm"
                        placeholder="Country"
                        name="title"
                        align="left"
                        value={poCountry.value}
                        state={poCountry.status}
                        errorMessage={poCountry.error}
                        options={
                            referenceCountriesOfBirth &&
                            JSON.stringify(
                                referenceCountriesOfBirth.map((option) => ({
                                    value: option.code,
                                    label: option.description,
                                }))
                            )
                        }
                        onOnSelect={(event) => dispatch(setPoCountryValue(event.detail.id))}
                    />
                </section>
            )}
            {!isPostalAddressSame && isPostalMethodPrivateBag && (
                <section>
                    <h6 className="medium no-bottom-margin">Please complete the following</h6>
                    <OmFormInputFieldWrapper
                        id="privateBag"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="Private bag number"
                        validationDelay={0}
                        value={privateBag.value}
                        state={privateBagInput?.status}
                        errorMessage={privateBagInput?.error}
                        onPersist={(event) => dispatch(setPrivateBagValue(event.detail))}
                        onInputBlur={() => setPrivateBagInput(privateBag)}
                        onFocus={() => setPrivateBagInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="privatePostOffice"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        size="full"
                        align="left"
                        placeholder="Post office"
                        validationDelay={0}
                        value={privatePostOffice.value}
                        state={privatePostOfficeInput?.status}
                        errorMessage={privatePostOfficeInput?.error}
                        onPersist={(event) => dispatch(setPrivatePostOfficeValue(event.detail))}
                        onInputBlur={() => setPrivatePostOfficeInput(privatePostOffice)}
                        onFocus={() => setPrivatePostOfficeInput(null)}
                    />
                    <OmFormInputFieldWrapper
                        id="privatePostalCode"
                        isSensitive
                        required
                        formId="personalDetailsForm"
                        keyboardMode="numeric"
                        size="full"
                        align="left"
                        placeholder="Postal code"
                        validationDelay={0}
                        value={privatePostalCode.value}
                        state={privatePostalCodeInput?.status}
                        errorMessage={privatePostalCodeInput?.error}
                        onPersist={(event) => dispatch(setPrivatePostalCodeValue(event.detail))}
                        onInputBlur={() => setPrivatePostalCodeInput(privatePostalCode)}
                        onFocus={() => setPrivatePostalCodeInput(null)}
                    />
                    <OmFormDropdownFieldWrapper
                        id="privatePostalCountry"
                        useNativeMobileDropdown
                        required
                        size="medium"
                        formId="personalDetailsForm"
                        placeholder="Country"
                        name="title"
                        align="left"
                        value={privateCountry.value}
                        state={privateCountry.status}
                        errorMessage={privateCountry.error}
                        options={
                            referenceCountriesOfBirth &&
                            JSON.stringify(
                                referenceCountriesOfBirth.map((option) => ({
                                    value: option.code,
                                    label: option.description,
                                }))
                            )
                        }
                        onOnSelect={(event) => dispatch(setPrivateCountryValue(event.detail.id))}
                    />
                </section>
            )}
        </>
    );
};

export default AddressDetailsSection;

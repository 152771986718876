import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
    getIsFilteredByCompliance,
    getIsFilteredByRiskProfile, getIsFilteredByStatus,
    getIsFilterOptionsSideDrawerOpen,
    getSelectedFundFilters,
    getNoFundFiltersSelected
} from '@om/redux/selectors/taxFreeSavingsAccount/advancedFundPicker';
import {
    COMPLIANCE_CHECKBOX_ITEMS,
    RISK_CHECKBOX_ITEMS,
    STATUS_CHECKBOX_ITEMS
} from '@om/redux/actions/taxFreeSavingsAccount/types';
import {
    setFilterOptions,
    toggleFilterOptionsDrawer,
} from '@om/redux/actions/taxFreeSavingsAccount/advancedFundPicker';
import {
    OmAccordion,
    OmAccordionItem,
    OmCheckBox,
} from '@om/component-library-react'
import OmButton from '../../../../components/forms/OmButton';
import SideDrawer from '../../../../components/SideDrawer';
import './FilterSideDrawer.scss'

const FilterSideDrawer = () => {
    const dispatch = useDispatch();
    const {
        isFilterOptionsSideDrawerOpen,
        selectedFundFilters,
        isFilteredByRiskProfile,
        isFilteredByCompliance,
        isFilteredByStatus,
        noFundFiltersSelected
    } = useSelector(
        createStructuredSelector({
            isFilterOptionsSideDrawerOpen: getIsFilterOptionsSideDrawerOpen,
            selectedFundFilters: getSelectedFundFilters,
            isFilteredByRiskProfile: getIsFilteredByRiskProfile,
            isFilteredByCompliance: getIsFilteredByCompliance,
            isFilteredByStatus: getIsFilteredByStatus,
            noFundFiltersSelected: getNoFundFiltersSelected
        })
    );

    const tempFundFilters = {...selectedFundFilters};
    const toggleTempFundFilter = (key, value) => tempFundFilters[key] = value.detail;

    return (
        <SideDrawer
            className="tfsa-advanced-fund-picker-filter-side-drawer"
            isOpen={isFilterOptionsSideDrawerOpen}
            onClose={() => dispatch(toggleFilterOptionsDrawer())}
        >
            <h6 className="no-top-margin small uppercase">Filter Funds</h6>

            <OmAccordion>
                <span slot="accordion-items">
                    <OmAccordionItem
                        class="accordion-item"
                        displayMode="page"
                        buttonText="RISK PROFILE"
                        expanded={isFilteredByRiskProfile || noFundFiltersSelected}
                    >
                        <div slot="panel-content">
                            <div className="filter-accordion-content">
                                {RISK_CHECKBOX_ITEMS.map(item => (
                                    <OmCheckBox
                                        fieldId={item.key}
                                        key={item.key}
                                        value={item.key}
                                        text={item.label}
                                        checked={selectedFundFilters[item.key]}
                                        onOnCheckBoxChanged={isChecked => toggleTempFundFilter(item.key, isChecked)}
                                    />
                                ))}
                            </div>
                        </div>
                    </OmAccordionItem>

                    <OmAccordionItem
                        className="accordion-item"
                        displayMode="page"
                        buttonText="COMPLIANCE"
                        expanded={isFilteredByCompliance}
                    >
                        <div slot="panel-content">
                            <div className="filter-accordion-content">
                                {COMPLIANCE_CHECKBOX_ITEMS.map(item => (
                                    <OmCheckBox
                                        id={item.key}
                                        key={item.key}
                                        value={item.key}
                                        text={item.label}
                                        checked={selectedFundFilters[item.key]}
                                        onOnCheckBoxChanged={isChecked => toggleTempFundFilter(item.key, isChecked)}
                                    />
                                ))}
                            </div>
                        </div>
                    </OmAccordionItem>

                    <OmAccordionItem
                        className="accordion-item"
                        displayMode="page"
                        buttonText="STATUS"
                        expanded={isFilteredByStatus}
                    >
                        <div slot="panel-content">
                            <div className="filter-accordion-content">
                                {STATUS_CHECKBOX_ITEMS.map(item => (
                                    <OmCheckBox
                                        id={item.key}
                                        key={item.key}
                                        value={item.key}
                                        text={item.label}
                                        checked={selectedFundFilters[item.key]}
                                        onOnCheckBoxChanged={isChecked => toggleTempFundFilter(item.key, isChecked)}
                                    />
                                ))}
                            </div>
                        </div>
                    </OmAccordionItem>
                </span>
            </OmAccordion>

            <div className={'action-buttons'}>
                <OmButton
                    text="Apply"
                    type="primary"
                    size="small"
                    onClick={() => {
                        dispatch(setFilterOptions(tempFundFilters));
                        dispatch(toggleFilterOptionsDrawer());
                    }}
                />
                <OmButton
                    text="Clear"
                    type="secondary"
                    size="small"
                    onClick={() => {
                        dispatch(setFilterOptions({}))
                        dispatch(toggleFilterOptionsDrawer());
                    }}
                />
            </div>
        </SideDrawer>
    );
};

export default FilterSideDrawer;

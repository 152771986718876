import React from 'react';
import PersonsDetailsSection from './PersonalDetailsFormSections/PersonsDetailsSection';
import ContactDetailsSection from './PersonalDetailsFormSections/ContactDetailsSection';
import './PersonalDetailsPage.scss';

const PersonalDetailsPage = () => {

    return (
        <div className="tfsa-personal-details-page">
            <div className="tfsa-personal-details-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">
                    Contact Information
                </h5>
                <PersonsDetailsSection />
                <ContactDetailsSection />
            </div>
        </div>
    );
};

export default PersonalDetailsPage;


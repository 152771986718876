import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { OmFormInputFieldWrapper, OmRadioButtonGroup } from '@om/component-library-react';

import {
    setContactForFeedbackValue,
    setContactMethodValue,
    setContactNumberValue,
    setEmailValue,
} from '@om/redux/actions/taxFreeSavingsAccount/personalDetails';
import {
    getContactForFeedback,
    getContactMethod,
    getContactNumber,
    getEmail,
} from '@om/redux/selectors/taxFreeSavingsAccount/personalDetails';
import { getReferenceNationalitiesList } from '@om/redux/selectors/taxFreeSavingsAccount/referenceData';

const ContactDetailsSection = () => {
    const [emailInput, setEmailInput] = useState();
    const [contactNumberInput, setcontactNumberInput] = useState();

    const dispatch = useDispatch();
    const { contactNumber, email, contactForFeedback, contactMethod } = useSelector(
        createStructuredSelector({
            contactNumber: getContactNumber,
            email: getEmail,
            contactForFeedback: getContactForFeedback,
            contactMethod: getContactMethod,

            referenceCountriesOfBirth: getReferenceNationalitiesList,
        })
    );

    return (
        <>
            <OmFormInputFieldWrapper
                id="email"
                isSensitive
                required
                formId="personalDetailsForm"
                size="full"
                align="left"
                placeholder="Email address"
                validationDelay={0}
                value={email.value}
                state={emailInput?.status}
                errorMessage={emailInput?.error}
                onPersist={(event) => dispatch(setEmailValue(event.detail))}
                onInputBlur={() => setEmailInput(email)}
                onFocus={() => setEmailInput(null)}
            />
            <OmFormInputFieldWrapper
                id="contactNumber"
                isSensitive
                required
                formId="personalDetailsForm"
                size="full"
                align="left"
                placeholder="Contact number"
                validationDelay={0}
                value={contactNumber.value}
                state={contactNumberInput?.status}
                errorMessage={contactNumberInput?.error}
                onPersist={(event) => dispatch(setContactNumberValue(event.detail))}
                onInputBlur={() => setcontactNumberInput(contactNumber)}
                onFocus={() => setcontactNumberInput(null)}
                hintText="E.g. 0821234567."
            />
            <div className="tfsa-contact-for-feedback-wrapper">
                <h6 className="no-bottom-margin" style={{ fontWeight: 500 }}>
                    Can we contact you if you encounter problems with this application process?
                </h6>
                <OmRadioButtonGroup
                    id="contactForFeedback"
                    groupName="contactForFeedbackGroup"
                    vertical={true}
                    value={contactForFeedback.value}
                    errorMessage={contactForFeedback.error}
                    onOnSelection={(event) => dispatch(setContactForFeedbackValue(event.detail))}
                >
                    <ul slot="list-items">
                        <li value="yes">Yes</li>
                        <li value="no">No</li>
                    </ul>
                </OmRadioButtonGroup>
            </div>
            {contactForFeedback.value === 'yes' && (
                <div className="tfsa-contact-method-wrapper">
                    <h6 className="no-bottom-margin" style={{ fontWeight: 500 }}>
                        Please select the preferred method of contact
                    </h6>
                    <OmRadioButtonGroup
                        id="contactMethod"
                        groupName="contactMethodGroup"
                        vertical={true}
                        value={contactMethod.value}
                        errorMessage={contactMethod.error}
                        onOnSelection={(event) => dispatch(setContactMethodValue(event.detail))}
                    >
                        <ul slot="list-items">
                            <li value="email">Email</li>
                            <li value="sms">SMS</li>
                            <li value="call">Call</li>
                        </ul>
                    </OmRadioButtonGroup>
                </div>
            )}
        </>
    );
};

export default ContactDetailsSection;

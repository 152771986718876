import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {navigate} from '@reach/router';
import {getHeaderStepText, getProgressBarIndex, getActivePageIndex} from '@om/redux/selectors/taxFreeSavingsAccount/routing';
import {getSavingFor, getYourGoal} from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupInitial';
import {PROGRESS_BAR_STEPS, PAGE_INDICES} from '@om/redux/actions/taxFreeSavingsAccount/types';
import {
    OmApplicationWizardHeader,
    OmApplicationProgressBar,
    OmIcon,
    Om1ColLayout,
} from '@om/component-library-react';
import OmButton from '../../components/forms/OmButton';
import { resetInvestmentSetupInitial } from '@om/redux/actions/taxFreeSavingsAccount/investmentSetupInitial';
import { resetInvestmentSetupContribution } from '@om/redux/actions/taxFreeSavingsAccount/investmentSetupContribution';
import { resetSelectAFund } from '@om/redux/actions/taxFreeSavingsAccount/selectAFund';
import { resetAdvancedFundPicker } from '@om/redux/actions/taxFreeSavingsAccount/advancedFundPicker';
import { resetFundSplit } from '@om/redux/actions/taxFreeSavingsAccount/fundSplit';
import { resetPersonalDetails } from '@om/redux/actions/taxFreeSavingsAccount/personalDetails';
// import { resetBeneficiaries } from '@om/redux/actions/taxFreeSavingsAccount/beneficiaries';
// import { resetBeneficiariesSplit } from '@om/redux/actions/taxFreeSavingsAccount/beneficiariesSplit';
import { resetPaymentDetails } from '@om/redux/actions/taxFreeSavingsAccount/paymentDetails';
import { resetReview } from '@om/redux/actions/taxFreeSavingsAccount/review';
import { resetRouting } from '@om/redux/actions/taxFreeSavingsAccount/routing';
import { resetScroll } from '@om/redux/actions/taxFreeSavingsAccount/scrolling';

const ApplicationWizardHeader = ({
    mobileHeaderTexture,
    homeUrl,
    bannerImageUrl,
    bannerText,
    stepTextCaption,
    callMeBack
}) => {
    const dispatch = useDispatch();

    const {
        progressBarIndex,
        headerStepText,
        savingFor,
        yourGoal,
        activePageIndex
    } = useSelector(
        createStructuredSelector({
            progressBarIndex: getProgressBarIndex,
            headerStepText: getHeaderStepText,
            savingFor: getSavingFor,
            yourGoal: getYourGoal,
            activePageIndex: getActivePageIndex
        })
    );

    const bannerImageClass = bannerImageUrl ? 'banner-image' : '';

    const getBannerText = () => {
        const savingForMessage = yourGoal.value ? yourGoal.value : savingFor.value;
        if (activePageIndex === 1) {
            return [bannerText, savingForMessage].join('');
        }
        
        return bannerText; 
    };

    const closeHander = () => {
        navigate('/');

        setTimeout(() => {
            dispatch(resetScroll());
            dispatch(resetRouting());
            dispatch(resetReview());
            dispatch(resetInvestmentSetupInitial());
            dispatch(resetInvestmentSetupContribution());
            dispatch(resetSelectAFund());
            dispatch(resetAdvancedFundPicker());
            dispatch(resetFundSplit());
            dispatch(resetPersonalDetails());
            // dispatch(resetBeneficiaries());
            // dispatch(resetBeneficiariesSplit());
            dispatch(resetPaymentDetails());
        }, 500);
    };

    return (
        <OmApplicationWizardHeader
            helpNumber="0860 665 463"
            className={bannerImageClass}
            headerTexture={mobileHeaderTexture ? mobileHeaderTexture.url : ''}
            bannerImageUrl={bannerImageUrl}
            bannerText={getBannerText()}
        >
            <div slot="wizard-title">
                {headerStepText && <p>{headerStepText}</p>}
                <h5><strong>Tax Free Savings Plan</strong></h5>
            </div>

            <span slot="contact-button">
                <OmButton
                    type='secondary'
                    actionType="Call Me Back"
                    size="small"
                    text="Call me back"
                    callMeBackObject={callMeBack ? JSON.stringify(callMeBack) : ''}
                />
            </span>

            <a slot="close-button">
                <OmIcon className="close-icon" iconName="close" onClick={() => closeHander()}/>
            </a>

            <div slot="progress-bar">
                <Om1ColLayout className="header">
                    <OmApplicationProgressBar
                        applicationName="Tax Free Savings Plan"
                        closable
                        hideDesktop={activePageIndex < PAGE_INDICES.AUXILIARY_DETAILS || activePageIndex > PAGE_INDICES.PAYMENT_DETAILS}
                        className={bannerImageClass}
                        bannerImageStyling={!!bannerImageUrl}
                        steps={PROGRESS_BAR_STEPS.join('|')}
                        index={progressBarIndex}
                        stepTextCaption={activePageIndex < PAGE_INDICES.REVIEW ? stepTextCaption : ''}
                        hideMobile={activePageIndex === PAGE_INDICES.THANK_YOU}>
                        <OmButton
                            actionType="url"
                            slot="close-application"
                            type="text"
                            size="small"
                            url="/"
                            text="CLOSE"
                        />
                    </OmApplicationProgressBar>
                </Om1ColLayout>
            </div>
        </OmApplicationWizardHeader>
    );
};

export default ApplicationWizardHeader;

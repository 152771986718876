import React from 'react';
import { useDispatch } from 'react-redux';
import '../AdvancedFundPickerPage.scss';

import { requestToggleFundSelection } from '@om/redux/actions/taxFreeSavingsAccount/advancedFundPicker';
import { OmCheckboxAccordionCard, OmLabeledValueSummary } from '@om/component-library-react';
import OmButton from '../../../../components/forms/OmButton';
import { FUND_OPTIONS_TO_CODE } from '@om/redux/actions/taxFreeSavingsAccount/types';

const FundCheckboxAccordionItem = ({
    title,
    subtitle,
    descriptionText,
    borderColour,
    investmentInfo,
    checkboxId,
    selected,
    className,
    buttonUrl,
    isInLeftColumn,
    fees,
}) => {
    const dispatch = useDispatch();
    const onCheckboxToggle = (event) => {
        event.stopPropagation();

        /**
         * The OmCheckboxAccordionCard requires the checkBoxId field to be an integer value. This, however, doesn't make
         * sense for the rest of the application. So we map it back to the unique fund name that is represented by the
         * integer checkBoxId value.
         */
        const idNameMapping = FUND_OPTIONS_TO_CODE[checkboxId];
        const fundId = idNameMapping || checkboxId;

        dispatch(requestToggleFundSelection(fundId, !selected, fees));
    };

    return (
        <OmCheckboxAccordionCard
            className={(className || '') + (isInLeftColumn ? 'in-left-column' : '')}
            checkBoxTitle={title}
            borderColour={borderColour}
            selected={selected}
            checkBoxId={checkboxId}
            onToggled={onCheckboxToggle}
        >
            <h6 slot="button-sub-title" className="small no-top-margin no-bottom-margin">
                {subtitle}
            </h6>
            <h6 slot="button-description" className="small no-top-margin no-bottom-margin">
                {descriptionText}
            </h6>
            <div slot="panel-content">
                <div className="investment-labels">
                    {investmentInfo &&
                        investmentInfo.map((info, index) => (
                            <div className="investment-label-row" key={`${checkboxId}_investment-labels_${index}`}>
                                <OmLabeledValueSummary className="description-text" value={info.label} />
                                {Array.isArray(info.value) ? (
                                    info.value.map((summary, key) => (
                                        <OmLabeledValueSummary
                                            className="three-values"
                                            key={`${checkboxId}_three-label_${key}`}
                                            label={summary.label}
                                            value={summary.value}
                                        />
                                    ))
                                ) : (
                                    <OmLabeledValueSummary className="single-value" value={info.value} />
                                )}
                            </div>
                        ))}
                </div>
            </div>

            <div slot="panel-button">
                <OmButton
                    className="button"
                    type="text"
                    size="small"
                    text="READ MORE"
                    actionType="url"
                    openTab
                    url={buttonUrl}
                />
            </div>
        </OmCheckboxAccordionCard>
    );
};

export default FundCheckboxAccordionItem;

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {fundSetValue} from '@om/redux/actions/taxFreeSavingsAccount/selectAFund';
import {
    getSelectedFund,
    getLowRiskDefaultFund,
    getMediumRiskDefaultFund,
    getHighRiskDefaultFund,
    getDefaultModerateFund
} from '@om/redux/selectors/taxFreeSavingsAccount/selectAFund';
import {
    OmApplicationRadioButtonAccordionGroup,
    OmApplicationPopupModal,
} from '@om/component-library-react';
import OmButton from '../../../components/forms/OmButton';
import {navigateToAdvancedFundPicker} from '@om/redux/actions/taxFreeSavingsAccount/routing';
import {FUND_OPTIONS} from '@om/redux/actions/taxFreeSavingsAccount/types';
import FundSelectionRadioAccordionItem from './FundSelectionRadioAccordionItem';
import {
    FIELD_STATUS,
} from '@om/redux/reducers/status';
import './SelectAFundPage.scss';
import { getIsMinimumMonthlyContributionEntered } from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupContribution';

const SelectAFundPage = () => {
    const dispatch = useDispatch();
    const {
        selectedFund,
        lowRiskDefaultFund,
        mediumRiskDefaultFund,
        highRiskDefaultFund,
        minimumMonthlyContributionEntered,
        moderateDefaultFund
    } = useSelector(
        createStructuredSelector({
            selectedFund: getSelectedFund,
            lowRiskDefaultFund: getLowRiskDefaultFund,
            mediumRiskDefaultFund: getMediumRiskDefaultFund,
            highRiskDefaultFund: getHighRiskDefaultFund,
            minimumMonthlyContributionEntered: getIsMinimumMonthlyContributionEntered,
            moderateDefaultFund: getDefaultModerateFund
        })
    );

    return (
        <div className="tfsa-fund-selection">
            <div className="tfsa-fund-selection-wrapper">
                { minimumMonthlyContributionEntered ?
                    <>
                        <h3 className="no-top-margin no-bottom-margin"><strong>Your investment setup</strong></h3>
                        <p>Your contributions will be invested in the Old Mutual Moderate Balanced Fund.</p>
                    </>
                    :
                    <>
                        <h3 className="no-top-margin no-bottom-margin">Choose the best option <strong>for your needs</strong></h3>
                        <p>Make a fund selection based on your investment goal and your level of risk tolerance.</p>
                    </>
                }


                <div className="accordion-container">
                    <OmApplicationRadioButtonAccordionGroup
                        className={minimumMonthlyContributionEntered ? 'single-fund' : ''}
                        selectedValue={selectedFund}
                        onOnSelection={selection => dispatch(fundSetValue(selection.detail))}
                    >
                        <div slot="radio-accordion-items">

                            { minimumMonthlyContributionEntered ?
                                <FundSelectionRadioAccordionItem
                                    selected={selectedFund.value === FUND_OPTIONS.MODERATE_INVESTOR.toString()}
                                    borderColour="freshGreen"
                                    radioButtonTitle="Comfortable investor"
                                    infoPopupHeading="What does Medium Risk mean?"
                                    infoPopupText="
                                        With medium risk, there is a moderate level of risk with your investment, but you can
                                        potentially achieve higher returns in the long-term compared to low-risk investments.
                                    "
                                    tickListItems={[
                                        'Seeks a medium-term inflation beating investment',
                                        'Aims to invest for 5 years+',
                                        'Prefers medium-risk investment ',
                                    ]}
                                    buttonId={FUND_OPTIONS.MODERATE_INVESTOR}
                                    fundName={moderateDefaultFund.fundName}
                                    factSheetUrl={moderateDefaultFund.factSheetUrl}
                                    investmentInfo={moderateDefaultFund.investmentInfo}
                                />
                                :
                                <>
                                    <FundSelectionRadioAccordionItem
                                        selected={selectedFund.value === FUND_OPTIONS.CAUTIOUS_INVESTOR.toString()}
                                        borderColour="futureGreen"
                                        radioButtonTitle="Cautious investor"
                                        infoPopupHeading="What does Low Risk mean?"
                                        infoPopupText="
                                            With low-risk, there is a lower possibility of losing your money in the short-term.
                                            However, the returns are likely to be lower than higher risk investments.
                                        "
                                        tickListItems={[
                                            'Seeks an investment that can offer a stable return by outperforming short term assets',
                                            'Aims to invest for 1 years+',
                                            'Prefers low-risk investment',
                                        ]}
                                        buttonId={FUND_OPTIONS.CAUTIOUS_INVESTOR}
                                        fundName={lowRiskDefaultFund.fundName}
                                        factSheetUrl={lowRiskDefaultFund.factSheetUrl}
                                        investmentInfo={lowRiskDefaultFund.investmentInfo}
                                    />

                                    <FundSelectionRadioAccordionItem
                                        selected={selectedFund.value === FUND_OPTIONS.COMFORTABLE_INVESTOR.toString()}
                                        borderColour="freshGreen"
                                        radioButtonTitle="Comfortable investor"
                                        infoPopupHeading="What does Medium Risk mean?"
                                        infoPopupText="
                                            With medium risk, there is a moderate level of risk with your investment, but you can
                                            potentially achieve higher returns in the long-term compared to low-risk investments.
                                        "
                                        tickListItems={[
                                            'Seeks a medium-term inflation beating investment',
                                            'Aims to invest for 5 years+',
                                            'Prefers medium-risk investment ',
                                        ]}
                                        buttonId={FUND_OPTIONS.COMFORTABLE_INVESTOR}
                                        fundName={mediumRiskDefaultFund.fundName}
                                        factSheetUrl={mediumRiskDefaultFund.factSheetUrl}
                                        investmentInfo={mediumRiskDefaultFund.investmentInfo}
                                    />

                                    <FundSelectionRadioAccordionItem
                                        selected={selectedFund.value === FUND_OPTIONS.ADVENTUROUS_INVESTOR.toString()}
                                        borderColour="heritageGreen"
                                        radioButtonTitle="Adventurous investor"
                                        infoPopupHeading="What does High Risk mean?"
                                        infoPopupText="
                                            Investing in a high-risk fund means that there is a potential to gain higher returns in the
                                            long term however, on the flip side, there is also a high chance of losing a substantial
                                            amount of the money you've invested.
                                        "
                                        tickListItems={[
                                            'This investor can tolerate unpredictable and rapid changes in stock market prices with a potential for high returns',
                                            'Aims to invest for 10 years+',
                                            'Comfortable with a high-risk investment',
                                        ]}
                                        buttonId={FUND_OPTIONS.ADVENTUROUS_INVESTOR}
                                        fundName={highRiskDefaultFund.fundName}
                                        factSheetUrl={highRiskDefaultFund.factSheetUrl}
                                        investmentInfo={highRiskDefaultFund.investmentInfo}
                                    />
                                </>
                            }

                        </div>
                    </OmApplicationRadioButtonAccordionGroup>
                </div>

                { !minimumMonthlyContributionEntered && 
                    <>
                        <OmButton
                            text="ADVANCED FUND PICKER"
                            type="secondary"
                            actionType="url"
                            size="small"
                            iconName="arrow_forward"
                            withIcon={true}
                            onClick={() => dispatch(navigateToAdvancedFundPicker())}
                        />
                        <p className="advanced-button-caption">*For experienced investors*</p>
                    </>
                }

                <OmApplicationPopupModal
                    exitable
                    dismissible
                    name="fundFormErrorModal"
                    open={selectedFund.status === FIELD_STATUS.ERROR}
                >
                    <div slot="content">
                        <p> <strong>Please make a fund selection</strong> to continue. </p>
                    </div>
                    <div slot="button">
                        <OmButton
                            type="primary"
                            size="small"
                            text="Okay, thanks"
                        />
                    </div>
                </OmApplicationPopupModal>
            </div>
        </div>
    );
};

export default SelectAFundPage;


import React from 'react';
import './AuxiliaryDetailsPage.scss';
import AddressDetailsSection from './auxiliaryDetailsFormSections/AddressDetailsSection';
import MinorDetailsSection from './auxiliaryDetailsFormSections/MinorDetailsSection';
import PersonalDetailsSection from './auxiliaryDetailsFormSections/PersonalDetailsSection';

const AuxiliaryDetailsPage = () => {
    return (
        <div className="tfsa-auxiliary-details-page">
            <div className="tfsa-auxiliary-details-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">
                    Good progress. Now, let's get formal
                </h5>
                <MinorDetailsSection />
                <PersonalDetailsSection />
                <AddressDetailsSection />
            </div>
        </div>
    );
};

export default AuxiliaryDetailsPage;

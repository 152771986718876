import { OmFormDropdownFieldWrapper, OmFormInputFieldWrapper } from '@om/component-library-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    setFirstNamesValue,
    setSurnameValue,
    setTitleValue,
} from '@om/redux/actions/taxFreeSavingsAccount/personalDetails';
import { getFirstNames, getSurname, getTitle } from '@om/redux/selectors/taxFreeSavingsAccount/personalDetails';
import { getReferenceTitles } from '@om/redux/selectors/taxFreeSavingsAccount/referenceData';

const PersonsDetailsSection = () => {
    const [firstNamesInput, setFirstNamesInput] = useState();
    const [surnameInput, setSurnameInput] = useState();
    const dispatch = useDispatch();
    const { title, firstNames, surname, referenceTitles } = useSelector(
        createStructuredSelector({
            title: getTitle,
            firstNames: getFirstNames,
            surname: getSurname,
            referenceTitles: getReferenceTitles,
        })
    );

    return (
        <>
            <OmFormDropdownFieldWrapper
                id="title"
                useNativeMobileDropdown
                required
                size="medium"
                formId="personalDetailsForm"
                placeholder="Title"
                name="title"
                align="left"
                value={title.value}
                state={title.status}
                errorMessage={title.error}
                options={
                    referenceTitles &&
                    JSON.stringify(referenceTitles.map((option) => ({ value: option.code, label: option.description })))
                }
                onOnSelect={(event) => dispatch(setTitleValue(event.detail.id))}
            />
            <OmFormInputFieldWrapper
                id="firstNames"
                isSensitive
                required
                formId="personalDetailsForm"
                size="full"
                align="left"
                placeholder="First name(s)"
                validationDelay={0}
                value={firstNames.value}
                state={firstNamesInput?.status}
                errorMessage={firstNamesInput?.error}
                onPersist={(event) => dispatch(setFirstNamesValue(event.detail))}
                onInputBlur={() => setFirstNamesInput(firstNames)}
                onFocus={() => setFirstNamesInput(null)}
            />
            <OmFormInputFieldWrapper
                id="surname"
                isSensitive
                required
                formId="personalDetailsForm"
                size="full"
                align="left"
                placeholder="Surname"
                validationDelay={0}
                value={surname.value}
                state={surnameInput?.status}
                errorMessage={surnameInput?.error}
                onPersist={(event) => dispatch(setSurnameValue(event.detail))}
                onInputBlur={() => setSurnameInput(surname)}
                onFocus={() => setSurnameInput(null)}
            />
        </>
    );
};

export default PersonsDetailsSection;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    getAllAdvancedFunds,
    getIsShowingMoreFunds,
    getSelectedFunds,
} from '@om/redux/selectors/taxFreeSavingsAccount/advancedFundPicker';
import { OmTabs } from '@om/component-library-react';
import OmButton from '../../../../components/forms/OmButton';
import { DEFAULT_FUND_OPTION_CODES, FUND_OPTIONS } from '@om/redux/actions/taxFreeSavingsAccount/types';
import { getDefaultFunds } from '@om/redux/selectors/taxFreeSavingsAccount/selectAFund';
import { toggleShowMoreFunds } from '@om/redux/actions/taxFreeSavingsAccount/advancedFundPicker';
import FundCheckboxList from '../fundFiltering/FundCheckboxList';
import FundCheckboxAccordionItem from '../fundFiltering/FundCheckboxAccordionItem';
import { TFSA_VIEW_MORE_FUNDS_ANCHOR_ID } from '@om/redux/middleware/rulesEngine/status';

// Select the first tab where funds are selected
const getDefaultActiveTab = (allAdvancedFunds, selectedFunds) => {
    if (allAdvancedFunds && selectedFunds) {
        const lowRiskFund = allAdvancedFunds.lowRisk.find((fund) => selectedFunds.hasOwnProperty(fund.fundCode));
        if (lowRiskFund && selectedFunds[lowRiskFund.fundCode]) return 1;

        const mediumRiskFund = allAdvancedFunds.mediumRisk.find((fund) => selectedFunds.hasOwnProperty(fund.fundCode));
        if (mediumRiskFund && selectedFunds[mediumRiskFund.fundCode]) return 2;

        const highRiskFund = allAdvancedFunds.highRisk.find((fund) => selectedFunds.hasOwnProperty(fund.fundCode));
        if (highRiskFund && selectedFunds[highRiskFund.fundCode]) return 3;
    }

    return 1;
};

const DefaultFundsView = () => {
    const dispatch = useDispatch();
    const { selectedFunds, isShowingMoreFunds, defaultFunds, allAdvancedFunds } = useSelector(
        createStructuredSelector({
            isShowingMoreFunds: getIsShowingMoreFunds,
            selectedFunds: getSelectedFunds,
            defaultFunds: getDefaultFunds,
            allAdvancedFunds: getAllAdvancedFunds,
        })
    );
    const { lowRisk, mediumRisk, highRisk } = defaultFunds;

    return (
        <div className="default-results-view">
            <p className="no-top-margin">
                <small>Most popular funds</small>
            </p>

            <div className="default-funds-wrapper">
                <FundCheckboxAccordionItem
                    subtitle="Low risk"
                    descriptionText="
                        This fund suits risk-averse investors with a short-term investment horizon and who want
                        yields in excess of those on money market investments.
                    "
                    borderColour="futureGreen"
                    isInLeftColumn
                    title={lowRisk.fundName}
                    buttonUrl={lowRisk.factSheetUrl}
                    investmentInfo={lowRisk.investmentInfo}
                    checkboxId={FUND_OPTIONS.CAUTIOUS_INVESTOR}
                    fees={lowRisk.fees}
                    selected={selectedFunds.value[DEFAULT_FUND_OPTION_CODES.CAUTIOUS_INVESTOR]}
                />

                <FundCheckboxAccordionItem
                    subtitle="Medium risk"
                    descriptionText="
                        Suitable for investors who want to grow their money in the medium to long term,
                        with fewer market changes in the short term.
                    "
                    borderColour="freshGreen"
                    title={mediumRisk.fundName}
                    buttonUrl={mediumRisk.factSheetUrl}
                    investmentInfo={mediumRisk.investmentInfo}
                    checkboxId={FUND_OPTIONS.COMFORTABLE_INVESTOR}
                    fees={mediumRisk.fees}
                    selected={selectedFunds.value[DEFAULT_FUND_OPTION_CODES.COMFORTABLE_INVESTOR]}
                />

                <FundCheckboxAccordionItem
                    subtitle="High risk"
                    descriptionText="
                        Suitable for investors who can tolerate the up and down changes in the stock market over
                        the long term.
                    "
                    borderColour="heritageGreen"
                    title={highRisk.fundName}
                    buttonUrl={highRisk.factSheetUrl}
                    investmentInfo={highRisk.investmentInfo}
                    checkboxId={FUND_OPTIONS.ADVENTUROUS_INVESTOR}
                    fees={highRisk.fees}
                    selected={selectedFunds.value[DEFAULT_FUND_OPTION_CODES.ADVENTUROUS_INVESTOR]}
                />
            </div>

            <OmButton
                className="view-less-more"
                type="secondary"
                size="small"
                iconName="arrow_forward"
                withIcon
                text={isShowingMoreFunds ? 'VIEW LESS FUNDS' : 'VIEW MORE FUNDS'}
                onClick={() => dispatch(toggleShowMoreFunds())}
            />

            {isShowingMoreFunds && (
                <OmTabs
                    id={TFSA_VIEW_MORE_FUNDS_ANCHOR_ID}
                    tabText1="Low risk"
                    tabText2="Medium risk"
                    tabText3="High risk"
                    defaultActiveTab={getDefaultActiveTab(allAdvancedFunds, selectedFunds.value)}
                >
                    <div slot="content-1">
                        <div className="default-funds-wrapper">
                            <FundCheckboxList fundList={allAdvancedFunds.lowRisk} selectedFunds={selectedFunds.value} />
                        </div>
                    </div>
                    <div slot="content-2">
                        <div className="default-funds-wrapper">
                            <FundCheckboxList
                                fundList={allAdvancedFunds.mediumRisk}
                                selectedFunds={selectedFunds.value}
                            />
                        </div>
                    </div>
                    <div slot="content-3">
                        <div className="default-funds-wrapper">
                            <FundCheckboxList
                                fundList={allAdvancedFunds.highRisk}
                                selectedFunds={selectedFunds.value}
                            />
                        </div>
                    </div>
                </OmTabs>
            )}
        </div>
    );
};

export default DefaultFundsView;

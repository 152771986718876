import {
    OmApplicationReviewSection,
    OmCheckBox,
    OmFormError,
    OmLabeledValueSummary,
    OmModalLoader,
} from '@om/component-library-react';
import { setTermsAndConditionsDrawer, toggleTermsAndConditions } from '@om/redux/actions/taxFreeSavingsAccount/review';
import {
    navigateToAuxiliaryDetails,
    navigateToBeneficiaries,
    navigateToInvestmentSetupContribution,
    navigateToInvestmentSetupInitial,
    navigateToPaymentDetails,
    navigateToPersonalDetails,
} from '@om/redux/actions/taxFreeSavingsAccount/routing';
import { displayFundName } from '@om/redux/functions/taxFreeSavingsAccount/selectAFund';
import {
    getAdvancedFundsAreSelected,
    getSelectedFundList,
} from '@om/redux/selectors/taxFreeSavingsAccount/advancedFundPicker';
import { getBeneficiaryList } from '@om/redux/selectors/taxFreeSavingsAccount/beneficiaries';
import { getBeneficiarySplitList } from '@om/redux/selectors/taxFreeSavingsAccount/beneficiariesSplit';
import {
    getContributionType,
    getEacInformation,
    getLumpSumContribution,
    getOwnPercentage,
    getPremiumEscalation,
    getPremiumEscalationDescription,
    getRegularContribution,
} from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupContribution';
import {
    getAccountNumber,
    getAccountType,
    getBankNameDescription,
    getDebitOrderDayText,
    getLumpSumMonth,
    getMonth,
} from '@om/redux/selectors/taxFreeSavingsAccount/paymentDetails';
import {
    getContactNumber,
    getEmail,
    getFirstNames,
    getSurname,
    getTitle,
} from '@om/redux/selectors/taxFreeSavingsAccount/personalDetails';
import { getReferenceAccountTypes } from '@om/redux/selectors/taxFreeSavingsAccount/referenceData';
import {
    getSubmissionStatus,
    getTermsAndConditionsError,
    getTermsAndConditionsValid,
} from '@om/redux/selectors/taxFreeSavingsAccount/review';
import { getFundName, getFundRiskProfile } from '@om/redux/selectors/taxFreeSavingsAccount/selectAFund';
import { navigate } from 'gatsby';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import EffectiveAnnualCostAccordian from './EffectiveAnnualCostAccordian';
import ErrorResponseModal from './ErrorResponseModal';
import FeesAndChargesAccordian from './FeesAndChargesAccordian';
import TermsAndCondtionsDrawer from './TermsAndConditionsDrawer';

import {
    PREMIUM_ESCALATION_OPTIONS,
    PRODUCT_DETAIL_PAGE,
    SUBMISSION_STATUS,
} from '@om/redux/actions/taxFreeSavingsAccount/types';
import {
    getDisplayPostalAddress,
    getDisplayResidentialAddress,
    getId,
    getIsPostalAddressSame,
} from '@om/redux/selectors/taxFreeSavingsAccount/auxiliaryDetails';
import { getSavingFor, getYourGoal } from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupInitial';
import './ReviewPage.scss';

const ReviewPage = () => {
    const dispatch = useDispatch();
    const {
        termsAndConditionsError,
        termsAndConditionsChecked,
        title,
        firstNames,
        surname,
        id,
        contactNumber,
        email,
        beneficiaryList,
        splitList,
        displayAddress,
        postalDisplayAddress,
        postalAddressSame,
        regularContribution,
        premiumEscalation,
        premiumEscalationDescription,
        ownPercentage,
        lumpSumContribution,
        contributionType,
        riskProfile,
        fundName,
        bankName,
        accountNumber,
        accountType,
        debitOrderMonth,
        lumpSumMonth,
        referenceAccountTypes,
        selectedFunds,
        goal,
        goalName,
        submissionStatus,
        advancedFundsSelected,
        debitOrderDayText,
        eacInformation,
    } = useSelector(
        createStructuredSelector({
            termsAndConditionsError: getTermsAndConditionsError,
            termsAndConditionsChecked: getTermsAndConditionsValid,
            title: getTitle,
            firstNames: getFirstNames,
            surname: getSurname,
            id: getId,
            contactNumber: getContactNumber,
            email: getEmail,
            beneficiaryList: getBeneficiaryList,
            splitList: getBeneficiarySplitList,
            displayAddress: getDisplayResidentialAddress,
            postalDisplayAddress: getDisplayPostalAddress,
            postalAddressSame: getIsPostalAddressSame,
            regularContribution: getRegularContribution,
            premiumEscalation: getPremiumEscalation,
            premiumEscalationDescription: getPremiumEscalationDescription,
            ownPercentage: getOwnPercentage,
            lumpSumContribution: getLumpSumContribution,
            contributionType: getContributionType,
            riskProfile: getFundRiskProfile,
            fundName: getFundName,
            bankName: getBankNameDescription,
            accountNumber: getAccountNumber,
            accountType: getAccountType,
            debitOrderMonth: getMonth,
            lumpSumMonth: getLumpSumMonth,
            referenceAccountTypes: getReferenceAccountTypes,
            selectedFunds: getSelectedFundList,
            goal: getSavingFor,
            goalName: getYourGoal,
            submissionStatus: getSubmissionStatus,
            advancedFundsSelected: getAdvancedFundsAreSelected,
            debitOrderDayText: getDebitOrderDayText,
            eacInformation: getEacInformation,
        })
    );

    const joinName = (nameArray) => {
        return nameArray.join(' ');
    };

    const mapToAccountTypeDescription = (accountType) => {
        const output = referenceAccountTypes.find((option) => accountType === option.code);
        return output.description;
    };

    const closeApplication = () => {
        // Good place to reset application if needed

        navigate(PRODUCT_DETAIL_PAGE);
    };

    return (
        <div className="tfsa-review-page">
            <div className="tfsa-review-page-wrapper">
                <h4 className="page-heading sessioncamhidetext">
                    Great choice, {firstNames.value}. <br></br>
                    Here’s an overview of <strong>your investment package</strong>
                </h4>
                <div>
                    <OmApplicationReviewSection titleHeader="Goals">
                        <span slot="edit-link" onClick={() => dispatch(navigateToInvestmentSetupInitial())}>
                            <h6 className="small">
                                <strong>EDIT</strong>
                            </h6>
                        </span>
                        <div slot="section-body">
                            <OmLabeledValueSummary label="Goal" value={goal.value} />
                            {goalName.value && <OmLabeledValueSummary label="Goal Name" value={goalName.value} />}
                        </div>
                    </OmApplicationReviewSection>
                    <OmApplicationReviewSection titleHeader="Product summary">
                        <span slot="edit-link" onClick={() => dispatch(navigateToInvestmentSetupContribution())}>
                            <h6 className="small">
                                <strong>EDIT</strong>
                            </h6>
                        </span>
                        <div slot="section-body">
                            <OmLabeledValueSummary label="Contribution type" value={contributionType.value} />
                            {regularContribution.value && (
                                <OmLabeledValueSummary
                                    label="Regular contribution"
                                    value={'R' + regularContribution.value + ' per month'}
                                />
                            )}
                            {premiumEscalation.value && (
                                <OmLabeledValueSummary
                                    label="Yearly payment increase"
                                    value={
                                        premiumEscalation.value === PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.key
                                            ? ownPercentage.value + '%'
                                            : premiumEscalationDescription
                                    }
                                />
                            )}
                            {lumpSumContribution.value && (
                                <OmLabeledValueSummary label="Lump sum" value={'R' + lumpSumContribution.value} />
                            )}
                            {selectedFunds && selectedFunds.length ? (
                                <>
                                    {selectedFunds.map((fund, index) => {
                                        if (!index)
                                            return (
                                                <OmLabeledValueSummary
                                                    key={index}
                                                    label={advancedFundsSelected ? 'Selected funds' : 'Associated fund'}
                                                    value={displayFundName(fund)}
                                                />
                                            );
                                        return <OmLabeledValueSummary key={index} value={displayFundName(fund)} />;
                                    })}
                                </>
                            ) : (
                                <>
                                    {riskProfile && <OmLabeledValueSummary label="Investor type" value={riskProfile} />}
                                    {fundName && <OmLabeledValueSummary label="Associated fund" value={fundName} />}
                                </>
                            )}
                        </div>
                    </OmApplicationReviewSection>

                    <OmApplicationReviewSection titleHeader="Personal details">
                        <span slot="edit-link" onClick={() => dispatch(navigateToAuxiliaryDetails())}>
                            <h6 className="small">
                                <strong>EDIT</strong>
                            </h6>
                        </span>
                        <div slot="section-body">
                            <OmLabeledValueSummary label="Title" value={title.value} />
                            <OmLabeledValueSummary
                                isSensitive={true}
                                label="Full name"
                                value={joinName([firstNames.value, surname.value])}
                            />
                            <OmLabeledValueSummary isSensitive={true} label="ID number" value={id.value} />
                        </div>
                    </OmApplicationReviewSection>

                    <OmApplicationReviewSection titleHeader="Contact details">
                        <span slot="edit-link" onClick={() => dispatch(navigateToPersonalDetails())}>
                            <h6 className="small">
                                <strong>EDIT</strong>
                            </h6>
                        </span>
                        <div slot="section-body">
                            <OmLabeledValueSummary
                                isSensitive={true}
                                label="Mobile number"
                                value={'+27 ' + contactNumber.value}
                            />
                            <OmLabeledValueSummary isSensitive={true} label="Email" value={email.value} />
                        </div>
                    </OmApplicationReviewSection>

                    <OmApplicationReviewSection titleHeader="Address details">
                        <span slot="edit-link" onClick={() => dispatch(navigateToAuxiliaryDetails())}>
                            <h6 className="small">
                                <strong>EDIT</strong>
                            </h6>
                        </span>
                        <div slot="section-body">
                            {postalAddressSame ? (
                                <OmLabeledValueSummary
                                    isSensitive={true}
                                    label="Residential / Postal address"
                                    value={displayAddress}
                                />
                            ) : (
                                <div>
                                    <OmLabeledValueSummary
                                        isSensitive={true}
                                        label="Residential address"
                                        value={displayAddress}
                                    />
                                    <OmLabeledValueSummary
                                        isSensitive={true}
                                        label="Postal address"
                                        value={postalDisplayAddress}
                                    />
                                </div>
                            )}
                        </div>
                    </OmApplicationReviewSection>

                    {beneficiaryList && beneficiaryList.length > 0 && (
                        <OmApplicationReviewSection titleHeader="Beneficiary details">
                            <span slot="edit-link" onClick={() => dispatch(navigateToBeneficiaries())}>
                                <h6 className="small">
                                    <strong>EDIT</strong>
                                </h6>
                            </span>
                            {beneficiaryList.length > 1 ? (
                                <div slot="section-body">
                                    {beneficiaryList.map((beneficiary, index) => {
                                        return (
                                            <div className="beneficiary-split-component" key={index}>
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    className="beneficiary-name"
                                                    label="Full name"
                                                    value={joinName([
                                                        beneficiary.value.firstName.value,
                                                        beneficiary.value.surname.value,
                                                    ])}
                                                />
                                                <OmLabeledValueSummary
                                                    className="beneficiary-split"
                                                    label="Split"
                                                    value={splitList[index].value + '%'}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div slot="section-body">
                                    <OmLabeledValueSummary
                                        isSensitive={true}
                                        className="beneficiary-name"
                                        label="Full name"
                                        value={joinName([
                                            beneficiaryList[0].value.firstName.value,
                                            beneficiaryList[0].value.surname.value,
                                        ])}
                                    />
                                </div>
                            )}
                        </OmApplicationReviewSection>
                    )}

                    <OmApplicationReviewSection titleHeader="Payment details">
                        <span slot="edit-link" onClick={() => dispatch(navigateToPaymentDetails())}>
                            <h6 className="small">
                                <strong>EDIT</strong>
                            </h6>
                        </span>
                        <div slot="section-body">
                            <OmLabeledValueSummary label="Bank name" value={bankName} />
                            <OmLabeledValueSummary
                                isSensitive={true}
                                label="Account holder name"
                                value={joinName([firstNames.value, surname.value])}
                            />
                            <OmLabeledValueSummary
                                isSensitive={true}
                                label="Account number"
                                value={accountNumber.value}
                            />
                            <OmLabeledValueSummary
                                label="Account type"
                                value={referenceAccountTypes && mapToAccountTypeDescription(accountType.value)}
                            />
                            <OmLabeledValueSummary label="Debit order day" value={debitOrderDayText} />
                            <OmLabeledValueSummary
                                label="Debit order starting month"
                                value={debitOrderMonth.value || lumpSumMonth.value}
                            />
                        </div>
                    </OmApplicationReviewSection>

                    <FeesAndChargesAccordian />

                    <EffectiveAnnualCostAccordian data={eacInformation} />

                    <div className="check-box-container">
                        <OmCheckBox
                            id="terms-and-conditions-checkbox"
                            checked={termsAndConditionsChecked}
                            onOnCheckBoxChanged={() => dispatch(toggleTermsAndConditions())}
                        >
                            <p slot="text-slot">
                                I accept the&nbsp;
                                <button onClick={() => dispatch(setTermsAndConditionsDrawer(true))}>
                                    Old Mutual Terms &amp; Conditions.
                                </button>
                            </p>
                        </OmCheckBox>
                        <OmFormError message={termsAndConditionsError} />
                    </div>
                    <TermsAndCondtionsDrawer />

                    <OmModalLoader
                        loaderText="Submitting your application"
                        verticalAlignMiddle
                        open={submissionStatus === SUBMISSION_STATUS.PENDING}
                    />

                    <ErrorResponseModal
                        open={submissionStatus === SUBMISSION_STATUS.ERROR}
                        buttonHandler={() => closeApplication()}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReviewPage;

import React, {useEffect, useState} from 'react';
import {Router, useLocation} from '@reach/router'
import {navigate, graphql} from 'gatsby';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {setActivePageIndex} from '@om/redux/actions/taxFreeSavingsAccount/routing';
import {getActivePageIndex, getLandingPage} from '@om/redux/selectors/taxFreeSavingsAccount/routing';
import {loadReferenceData} from '@om/redux/actions/taxFreeSavingsAccount/referenceData';
import {getScrollToId} from '@om/redux/selectors/taxFreeSavingsAccount/scrolling';
import {resetScroll} from '@om/redux/actions/taxFreeSavingsAccount/scrolling';
import {TFSA_PAGE_URLS, PAGES, PAGE_INDICES} from '@om/redux/actions/taxFreeSavingsAccount/types';

import {OmApplicationPage} from '@om/component-library-react'
import ApplicationLayout from '../layouts/ApplicationLayout';
import ApplicationWizardHeader from '../applications/taxFreeSavingsAccount/ApplicationWizardHeader';
import ApplicationWizardFooter from '../applications/taxFreeSavingsAccount/ApplicationWizardFooter';
import InvestmentSetupInitialPage from '../applications/taxFreeSavingsAccount/investmentSetupInitialPage/InvestmentSetupInitialPage';
import PersonalDetailsPage from '../applications/taxFreeSavingsAccount/personalDetailsPage/PersonalDetailsPage';
import AuxiliaryDetailsPage from '../applications/taxFreeSavingsAccount/auxiliaryDetailsPage/AuxiliaryDetailsPage'
// import BeneficiariesPage from '../applications/taxFreeSavingsAccount/beneficiariesPage/BeneficiariesPage';
import PaymentDetailsPage from '../applications/taxFreeSavingsAccount/paymentDetailsPage/PaymentDetailsPage';
import ReviewPage from '../applications/taxFreeSavingsAccount/reviewPage/ReviewPage';
import ThankYouPage from '../applications/taxFreeSavingsAccount/thankYouPage/ThankYouPage';
// import BeneficiariesSplitPage from '../applications/taxFreeSavingsAccount/beneficiariesSplitPage/BeneficiariesSplitPage';
import InvestmentSetupContributionPage from '../applications/taxFreeSavingsAccount/investmentSetupContributionPage/InvestmentSetupContributionPage';
import SelectAFundPage from '../applications/taxFreeSavingsAccount/selectAFundPage/SelectAFundPage';
import AdvancedFundPickerPage from '../applications/taxFreeSavingsAccount/advancedFundPickerPage/AdvancedFundPickerPage';
import FundSplitPage from '../applications/taxFreeSavingsAccount/fundSplitPage/FundSplitPage';
import { loadFundData } from '@om/redux/actions/taxFreeSavingsAccount/fundData';

import { getRequestId } from '@om/redux/selectors/taxFreeSavingsAccount/sessionCamVariables';
import { useSessionCamVariables } from '../hooks/useSessionCamVariables';

const TfsaApplicationPage = ({ data }) => {
    const [isCorrectRoute, setIsCorrectRoute] = useState(false);
    const {
        landing_page,
        // page_background_url,
        mobile_header_texture,
        wizard_header_banner,
        investment_setup_initial_product_cards,
        // include_cvs,
        include_avs,
        include_cdv,
        thank_you,
        thank_you_page_four_column_illustration,
        call_me_back
    } = data.contentstackTaxFreeSavingsAccountApplication;

    let bannerImageUrl, bannerText;
    if (wizard_header_banner && wizard_header_banner[0]) {
        const {
            banner_image,
            banner_text,
        } = wizard_header_banner[0];

        bannerImageUrl = banner_image && banner_image.url
        bannerText = banner_text;
    }

    const dispatch = useDispatch();
    const { landingPage, activePageIndex, scrollToId, requestId} = useSelector(
        createStructuredSelector({
            landingPage: getLandingPage,
            activePageIndex: getActivePageIndex,
            scrollToId: getScrollToId,
            requestId: getRequestId,
        })
    );

    useEffect(() => {
        dispatch(loadReferenceData());
        dispatch(loadFundData());
    }, [dispatch])

    // Route guarding
    const location = useLocation();
    useEffect(() => {
        const currentPageIndex = PAGES.map(page => page.URL).indexOf(location.pathname);
        if (activePageIndex === PAGE_INDICES.LANDING_PAGE) {
            dispatch(setActivePageIndex(PAGE_INDICES.INVESTMENT_SETUP_INITIAL));
            navigate(landingPage, {replace: true});
        } else if (currentPageIndex !== activePageIndex) {
            navigate(PAGES[activePageIndex].URL, {replace: true});
            dispatch(setActivePageIndex(activePageIndex));
        } else {
            setIsCorrectRoute(true);
        }
    }, [activePageIndex, dispatch, location.pathname, landingPage]);
    
    useSessionCamVariables(requestId);

    if (!isCorrectRoute) return (<></>);
    return (
        <ApplicationLayout>
            <OmApplicationPage
                scrollToId={scrollToId}
                onScrollEnd={() => dispatch(resetScroll())}
            >
                <div slot="header">
                    <ApplicationWizardHeader
                        mobileHeaderTexture={mobile_header_texture}
                        bannerImageUrl={bannerImageUrl}
                        bannerText={bannerText}
                        stepTextCaption="Setting up my"
                        callMeBack={call_me_back}
                    />
                </div>

                <div slot="content-no-sidebar">
                    <Router>
                        <InvestmentSetupInitialPage
                            investmentSetupInitialProductCards={investment_setup_initial_product_cards}
                            landingPage={landing_page}
                            path={TFSA_PAGE_URLS.INVESTMENT_SETUP_INITIAL}
                        />
                        <PersonalDetailsPage path={TFSA_PAGE_URLS.PERSONAL_DETAILS} />
                        <InvestmentSetupContributionPage 
                            path={TFSA_PAGE_URLS.INVESTMENT_SETUP_CONTRIBUTION} 
                            callMeBack={call_me_back}/>
                        <SelectAFundPage path={TFSA_PAGE_URLS.SELECT_A_FUND} />
                        <AdvancedFundPickerPage path={TFSA_PAGE_URLS.ADVANCED_FUND_PICKER} />
                        <FundSplitPage path={TFSA_PAGE_URLS.FUND_SPLIT} />
                        <AuxiliaryDetailsPage path={TFSA_PAGE_URLS.AUXILIARY_DETAILS}/>
                        {/*<BeneficiariesPage path={TFSA_PAGE_URLS.BENEFICIARIES} />*/}
                        {/*<BeneficiariesSplitPage path={TFSA_PAGE_URLS.BENEFICIARIES_SPLIT} />*/}
                        <PaymentDetailsPage
                            path={TFSA_PAGE_URLS.PAYMENT_DETAILS}
                            includeAvs={include_avs}
                            includeCdv={include_cdv}
                        />
                        <ReviewPage path={TFSA_PAGE_URLS.REVIEW} />
                        <ThankYouPage
                            path={TFSA_PAGE_URLS.THANK_YOU}
                            thankYouEntry={thank_you}
                            fourColumnLayout={thank_you_page_four_column_illustration}
                            // callMeBack={call_me_back_generic}
                        />
                    </Router>

                </div>

                <div slot="side-drawer">
                </div>

                <div slot="footer">
                    <ApplicationWizardFooter />
                </div>
            </OmApplicationPage>
        </ApplicationLayout>
    );
};

export const pageQuery = graphql`
    query ModularTfsaApplicationPageQuery($id: String!) {
        contentstackTaxFreeSavingsAccountApplication(id: { eq: $id }) {
            title
            landing_page
            page_background_url {
                ...File
            }
            mobile_header_texture {
                ...File
            }
            wizard_header_banner {
                banner_text
                banner_image {
                    ...File
                }
            }
            investment_setup_initial_product_cards {
                ...RefinedProductCard
            }
            include_cvs
            include_avs
            include_cdv
            thank_you {
                ...ThankYou
            }
            thank_you_page_four_column_illustration {
                ...FourColumnIllustration
            }
            call_me_back {
                ...CallMeBack
            }
        }
    }
`;

export default TfsaApplicationPage;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    getTotalContribution,
    getSplitFieldList,
    getIsEvenSplit,
    getFundSplitTotal,
    getShowAddMoreFunds,
} from '@om/redux/selectors/taxFreeSavingsAccount/fundSplit';
import {
    OmApplicationFundSplitList,
    OmApplicationFundSplitCard,
    OmFormInputFieldWrapper,
    OmApplicationPopupModal,
} from '@om/component-library-react';
import {
    deleteFund,
    deselectEvenSplit,
    discardEvenSplit,
    selectEvenSplit,
    setSplitPercentageValue,
} from '@om/redux/actions/taxFreeSavingsAccount/fundSplit';
import {
    TFSA_FUND_SPLIT_LUMP_SUM_CARD_ID_PREFIX,
    TFSA_FUND_SPLIT_REGULAR_CARD_ID_PREFIX,
} from '@om/redux/middleware/rulesEngine/status';
import {
    getDisplayLumpSumInput,
    getDisplayRegularInput,
} from '@om/redux/selectors/taxFreeSavingsAccount/investmentSetupContribution';
import { FUND_SPLIT_FORM, FUND_SPLIT_TYPE } from '@om/redux/actions/taxFreeSavingsAccount/types';
import { DEFAULT_VALIDATION_DELAY } from '../../config';
import './FundSplitPage.scss';
import { navigateToAdvancedFundPicker } from '@om/redux/actions/taxFreeSavingsAccount/routing';
import OmButton from '../../../components/forms/OmButton';

const FundSplitCards = ({ splitFieldList, idPrefix, onDelete, onPersist, hideDeleteButton, disableInputField }) =>
    splitFieldList.map((splitField, index) => (
        <OmApplicationFundSplitCard
            key={`${idPrefix}${index}`}
            errorMessage={splitField.error}
            fundName={splitField.fundName}
            value={`R${splitField.contributionSplit}`}
            onOnDelete={() => onDelete(splitField.id)}
            hideDeleteButton={hideDeleteButton}
        >
            <div slot="percentage-input">
                <OmFormInputFieldWrapper
                    id={`${idPrefix}${index}`}
                    value={splitField.value}
                    state={disableInputField ? 'disabled' : splitField.status}
                    mask="number"
                    maxlength="2"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    align="right"
                    onPersist={(event) => {
                        return onPersist({
                            event,
                            fundCode: splitField.fundCode,
                            contribution: event.detail,
                        });
                    }}
                />
            </div>
        </OmApplicationFundSplitCard>
    ));

const FundSplitPage = () => {
    const [showConfirmDelete, setShowConfirmDelete] = useState([false, null]);
    const dispatch = useDispatch();
    const {
        totalContribution,
        splitFieldList,
        isEvenSplit,
        fundSplitTotal,
        displayRegularInput,
        displayLumpSumInput,
        showAddMoreFunds,
    } = useSelector(
        createStructuredSelector({
            totalContribution: getTotalContribution,
            splitFieldList: getSplitFieldList,
            isEvenSplit: getIsEvenSplit,
            fundSplitTotal: getFundSplitTotal,
            displayRegularInput: getDisplayRegularInput,
            displayLumpSumInput: getDisplayLumpSumInput,
            showAddMoreFunds: getShowAddMoreFunds,
        })
    );

    const onAddMoreFunds = () => dispatch(navigateToAdvancedFundPicker());

    const onPersist = (fundType) => ({ event, contribution, fundCode }) => {
        dispatch(setSplitPercentageValue(event.detail, fundCode, contribution, fundType));
    };

    const onDelete = (id) => {
        dispatch(deleteFund(id));
        setShowConfirmDelete([false, null]);
    };

    const checkBoxChanged = (fundType, isEvenSplit) => {
        // If there is only one fund then do nothing, fyi checkbox disabled as prop on component
        if (splitFieldList.regular.length === 1 || splitFieldList.lumpSum.length === 1) return;

        // If even split is being deselected
        if (isEvenSplit) {
            dispatch(deselectEvenSplit(fundType));
            dispatch(discardEvenSplit(fundType));
            return;
        }

        dispatch(selectEvenSplit(fundType));
    };

    return (
        <div className="tfsa-fund-split-page">
            <div className="tfsa-fund-split-page-wrapper">
                {displayRegularInput && (
                    <>
                        <h5 className="no-bottom-margin no-top-margin">
                            Split your <strong>regular</strong> contribution between the funds
                        </h5>
                        <h6>How would you like to split the funds?</h6>
                        <OmApplicationFundSplitList
                            checkboxId="regular-checkbox"
                            evenSplit={isEvenSplit.regular}
                            errorMessage={fundSplitTotal.regular.error}
                            state={fundSplitTotal.regular.state}
                            totalPercentage={fundSplitTotal.regular.percentage}
                            totalValue={`R${totalContribution.regular}`}
                            showAddMoreFundsButton={showAddMoreFunds.regular}
                            onButtonClicked={onAddMoreFunds}
                            disableCheckBox={splitFieldList.regular.length === 1}
                            onCheckBoxChanged={() => checkBoxChanged(FUND_SPLIT_TYPE.REGULAR, isEvenSplit.regular)}
                        >
                            <div slot="fund-cards">
                                <FundSplitCards
                                    splitFieldList={splitFieldList.regular}
                                    idPrefix={TFSA_FUND_SPLIT_REGULAR_CARD_ID_PREFIX}
                                    onDelete={(id) => setShowConfirmDelete([true, id])}
                                    onPersist={onPersist(FUND_SPLIT_FORM.REGULAR)}
                                    hideDeleteButton={splitFieldList.regular.length === 1}
                                    disableInputField={splitFieldList.regular.length === 1}
                                />
                            </div>
                        </OmApplicationFundSplitList>
                    </>
                )}

                {displayLumpSumInput && (
                    <>
                        <h5 className="no-bottom-margin">
                            Split your <strong>lump sum</strong> contribution between the funds{' '}
                        </h5>
                        <h6>How would you like to split the funds?</h6>
                        <OmApplicationFundSplitList
                            checkboxId="lump-sum-checkbox"
                            evenSplit={isEvenSplit.lumpSum}
                            errorMessage={fundSplitTotal.lumpSum.error}
                            state={fundSplitTotal.lumpSum.state}
                            totalPercentage={fundSplitTotal.lumpSum.percentage}
                            totalValue={`R${totalContribution.lumpSum}`}
                            showAddMoreFundsButton={showAddMoreFunds.lumpSum}
                            onButtonClicked={onAddMoreFunds}
                            disableCheckBox={splitFieldList.lumpSum.length === 1}
                            onCheckBoxChanged={() => checkBoxChanged(FUND_SPLIT_TYPE.LUMP_SUM, isEvenSplit.lumpSum)}
                        >
                            <div slot="fund-cards">
                                <FundSplitCards
                                    splitFieldList={splitFieldList.lumpSum}
                                    idPrefix={TFSA_FUND_SPLIT_LUMP_SUM_CARD_ID_PREFIX}
                                    onDelete={(id) => setShowConfirmDelete([true, id])}
                                    onPersist={onPersist(FUND_SPLIT_FORM.LUMP_SUM)}
                                    hideDeleteButton={splitFieldList.regular.length === 1}
                                    disableInputField={splitFieldList.regular.length === 1}
                                />
                            </div>
                        </OmApplicationFundSplitList>
                    </>
                )}

                <OmApplicationPopupModal
                    exitable
                    dismissible
                    name="fundSplitFormErrorModal"
                    open={showConfirmDelete[0]}
                    onModalClosed={() => setShowConfirmDelete([false, showConfirmDelete[1]])}
                >
                    <div slot="content">
                        <p>
                            Do you want to <strong>remove this fund?</strong>
                        </p>
                    </div>
                    <div slot="button">
                        <OmButton
                            type="primary"
                            size="small"
                            text="Yes, remove"
                            onClick={() => onDelete(showConfirmDelete[1])}
                        />
                    </div>
                </OmApplicationPopupModal>
            </div>
        </div>
    );
};

export default FundSplitPage;

import React from 'react';
import { OmApplicationPopupModal } from '@om/component-library-react';

const ErrorSelectionModal = ({ open, closeHandler }) => {
    return (
        <OmApplicationPopupModal
            onModalClosed={() => closeHandler()}
            name="ErrorResponseModal"
            dismissible="true"
            open={open}>
            <div slot="content">
                <h5 className="no-bottom-margin"><strong>Please make a selection</strong> to continue.</h5>
            </div>
        </OmApplicationPopupModal>
    );
};

export default ErrorSelectionModal;
import { OmAccordionItem } from '@om/component-library-react';
import React from 'react';

const EffectiveAnnualCostAccordian = ({ data }) => {
    return (
        <OmAccordionItem buttonText="Effective Annual Cost" displayMode="card" expanded="false" hasSeparator="true">
            <span slot="panel-content">
                <div>
                    <p>
                        <strong>Example EAC - {data.copy}</strong>
                    </p>
                    <p>
                        The Effective Annual Cost (EAC) is a measure which has been introduced to allow you to compare
                        the charges you incur and their impact on investment returns when you invest in different
                        financial products. It is expressed as an annualised percentage. The EAC is made up of four
                        charges, which are added together, as shown in the table below. Some of the charges may vary,
                        depending on your investment period.
                        <strong>
                            <i>
                                The EAC calculation assumes that an investor terminates his or her investment in the
                                financial product at the end of relevant periods shown in the table.
                            </i>
                        </strong>
                    </p>

                    <table className="eac-table">
                        <thead>
                            <tr>
                                <th className="column-1 change-column" rowSpan="2">
                                    <p className="medium">
                                        <strong>Impact of future charges</strong>
                                    </p>
                                </th>
                                <th className="column-2">
                                    <p className="small">
                                        <strong>Next 1 year</strong>
                                    </p>
                                </th>
                                <th className="column-3">
                                    <p className="small">
                                        <strong>Next 3 years</strong>
                                    </p>
                                </th>
                                <th className="column-4">
                                    <p className="small">
                                        <strong>Next 5 years</strong>
                                    </p>
                                </th>
                                <th className="column-5">
                                    <p className="small">
                                        <strong>Next 10 years</strong>
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="column-1">
                                    <p className="medium">Investment Management</p>
                                </td>
                                <td className="column-2">
                                    <p className="medium">{data.eac.INVESTMENT_MANAGEMENT[0].toString()}%</p>
                                </td>
                                <td className="column-3">
                                    <p className="medium">{data.eac.INVESTMENT_MANAGEMENT[1].toString()}%</p>
                                </td>
                                <td className="column-4">
                                    <p className="medium">{data.eac.INVESTMENT_MANAGEMENT[2].toString()}%</p>
                                </td>
                                <td className="column-5">
                                    <p className="medium">{data.eac.INVESTMENT_MANAGEMENT[3].toString()}%</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="column-1">
                                    <p className="medium">Advice</p>
                                </td>
                                <td className="column-2">
                                    <p className="medium">{data.eac.ADVICE[0].toString()}%</p>
                                </td>
                                <td className="column-3">
                                    <p className="medium">{data.eac.ADVICE[1].toString()}%</p>
                                </td>
                                <td className="column-4">
                                    <p className="medium">{data.eac.ADVICE[2].toString()}%</p>
                                </td>
                                <td className="column-5">
                                    <p className="medium">{data.eac.ADVICE[3].toString()}%</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="column-1">
                                    <p className="medium">Administration</p>
                                </td>
                                <td className="column-2">
                                    <p className="medium">{data.eac.ADMIN[0].toString()}%</p>
                                </td>
                                <td className="column-3">
                                    <p className="medium">{data.eac.ADMIN[1].toString()}%</p>
                                </td>
                                <td className="column-4">
                                    <p className="medium">{data.eac.ADMIN[2].toString()}%</p>
                                </td>
                                <td className="column-5">
                                    <p className="medium">{data.eac.ADMIN[3].toString()}%</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="column-1">
                                    <p className="medium">Other</p>
                                </td>
                                <td className="column-2">
                                    <p className="medium">{data.eac.OTHER[0].toString()}%</p>
                                </td>
                                <td className="column-3">
                                    <p className="medium">{data.eac.OTHER[1].toString()}%</p>
                                </td>
                                <td className="column-4">
                                    <p className="medium">{data.eac.OTHER[2].toString()}%</p>
                                </td>
                                <td className="column-5">
                                    <p className="medium">{data.eac.OTHER[3].toString()}%</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="column-1">
                                    <p className="medium">Effective annual cost</p>
                                </td>
                                <td className="column-2 last-row">
                                    <p className="medium">{data.eac.EAC[0].toString()}%</p>
                                </td>
                                <td className="column-3 last-row">
                                    <p className="medium">{data.eac.EAC[1].toString()}%</p>
                                </td>
                                <td className="column-4 last-row">
                                    <p className="medium">{data.eac.EAC[2].toString()}%</p>
                                </td>
                                <td className="column-5 last-row">
                                    <p className="medium">{data.eac.EAC[3].toString()}%</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <p>
                        * When you have more than one Old Mutual Invest Plan (i.e. a Tax Free Plan and / or Flexible
                        Plan), these plans make up your Old Mutual Invest Portfolio. The total investment value and the
                        total yearly regular investments that you have indicated are taken into account when calculating
                        the fees and charges for your total Old Mutual Invest Portfolio so that you may get the benefit
                        of lower fees.
                    </p>
                    <p>
                        <strong>The different categories of charges can be explained as follows:</strong>
                    </p>
                    <p>
                        <strong>INVESTMENT MANAGEMENT CHARGES</strong>
                    </p>

                    <p>
                        This category is a measure of the costs of managing the selected investment fund(s) chosen and
                        is represented by the sum of the Total Expense Ratio (TER) and Transaction Costs (TC) applicable
                        to the funds. These costs will include the asset management fees, any applicable performance
                        fees and also the costs for trading the underlying assets. These charges are deducted from the
                        investment fund(s) by the fund managers of the underlying investment funds(s). The percentage
                        charge shown in the table is the average based on your first year's premium allocation across
                        the investment funds chosen.
                    </p>

                    <p>
                        <strong>ADVICE CHARGES</strong>
                    </p>
                    <p>No Advice fees are applicable when you invest online.</p>
                    <p>
                        <strong>ADMINISTRATION CHARGES</strong>
                    </p>
                    <p>
                        This category includes all charges you pay for the administration of the product. Refer to the
                        charges section for full details.
                    </p>

                    <p>
                        <strong>OTHER CHARGES</strong>
                    </p>
                    <p>
                        This category includes charges that do not fall into the previous three categories. For this
                        plan, the following charge is included in this category:
                    </p>
                    <ul>
                        <li>
                            An{' '}
                            <strong>
                                <i>administration fee refund</i>
                            </strong>{' '}
                            is payable if you invest at least R33 000, in that tax year, across your Old Mutual Invest
                            Plans (i.e. Tax Free Plan and / or Flexible Plans), or whether you have a total fund value
                            of at least R1 000 000 across your Old Mutual Invest Plans. This will reduce the total EAC.
                            Refer to the refund of administration fee section for full details.
                        </li>
                    </ul>
                </div>
            </span>
        </OmAccordionItem>
    );
};

export default EffectiveAnnualCostAccordian;
